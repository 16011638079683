import $ from "jquery";
import boardHandler from "./board";
/* if(location.pathname.split('/')[2] == "signup"){
    signupForm()
} */

/**
 * Signup form handling
 */
export default class authHandler {
  static signupForm() {
    $("#signupButton").click(function (e) {
      e.preventDefault();
      $(".error_message").remove();
      var a = "signup";
      var postObj = new FormData();

      postObj.append("email", $("#signupEmail").val());
      postObj.append("password", $("#signupPassword").val());
      postObj.append(
        "password-confirmation",
        $("#password-confirmation").val()
      );

      let country_code = authHandler.getPassCountry($("#country").val());

      postObj.append("firstname", $("#firstname").val());
      postObj.append("lastname", $("#lastname").val());
      postObj.append("phone", $("#phone").val());
      postObj.append("address_full", $("#address_full").val());
      postObj.append("city", $("#city").val());
      postObj.append("zipcode", $("#zipcode").val());
      postObj.append("country", $("#country").val());
      postObj.append("country_code", country_code);
      postObj.append("address_type", "main");

      $.ajax({
        url: a,
        type: "POST",
        data: postObj,
        contentType: false,
        processData: false,
        success: function (data) {
          data = JSON.parse(data);
          // If something
          if (data["error"]) {
            //display error
            boardHandler.showNotification(
              data["error"].type,
              data["error"].title,
              data["error"].message
            );
          }
          if (data["success"]) {
            // Mail has been sent with informations
            boardHandler.showNotification(
              data["success"].type,
              data["success"].title,
              data["success"].message
            );

            setTimeout(() => {
              window.location.href = "/auth?login=true";
            }, 3000);
          }
          // Else its good
          // User has been added
        },
      });
    });
  }

  static loginForm() {
    $("#loginButton").click(function (e) {
      e.preventDefault();
      $(".error_message").remove();
      var a = "login";
      var postObj = new FormData();
      postObj.append("email", $("#loginEmail").val());
      postObj.append("password", $("#loginPassword").val());

      $.ajax({
        url: a,
        type: "POST",
        data: postObj,
        contentType: false,
        processData: false,
        success: function (data) {
          data = JSON.parse(data);
          // If something
          if (data["error"]) {
            //display error
            if (data["error"].errorCode == 3) {
              if (document.querySelector("#create-verification")) {
                document.querySelector("#create-verification").remove();
              }

              let authContainer = document.querySelector(".auth-container");

              var element = document.createElement("a");
              element.setAttribute("href", "/resend-verification-link");
              element.id = "resendVerificationButton";
              element.append("Renvoyer un email de vérification");

              authContainer.appendChild(element);
              authHandler.resendVerificationLink();
            }
            boardHandler.showNotification(
              data["error"].type,
              data["error"].title,
              data["error"].message
            );
          }
          if (data["success"]) {
            window.location.replace("/board");
          }
          // Else its good
          // User has been added
        },
      });
    });
  }

  static resendVerificationLink() {
    $("#resendVerificationButton").click(function (e) {
      e.preventDefault();
      var a = "/resend-verification-link";
      var postObj = new FormData();
      postObj.append("email", $("#loginEmail").val());
      postObj.append("password", $("#loginPassword").val());
      if ($("#loginEmail").val() != "" && $("#loginPassword").val() != "") {
        $.ajax({
          url: a,
          type: "POST",
          data: postObj,
          contentType: false,
          processData: false,
          success: function (data) {
            data = JSON.parse(data);
            // If something
            if (data["error"]) {
              // display error
              boardHandler.showNotification(
                data["error"].type,
                data["error"].title,
                data["error"].message
              );
            } else if (data["success"]) {
              // Mail has been sent with informations
              boardHandler.showNotification(
                data["success"].type,
                data["success"].title,
                data["success"].message
              );
            }
          },
        });
      } else {
        boardHandler.showNotification(
          "error",
          "Erreur formulaire",
          "Informations manquantes dans le formulaire"
        );
      }
    });
  }

  static resetPasswordForm() {
    $("#resetPasswordButton").click(function (e) {
      e.preventDefault();
      $(".error_message").remove();
      var a = "/forgottenpwd/reset-password";
      var postObj = new FormData();
      postObj.append("email", $("#email").val());

      $.ajax({
        url: a,
        type: "POST",
        data: postObj,
        contentType: false,
        processData: false,
        success: function (data) {
          data = JSON.parse(data);
          // If something
          if (data["error"]) {
            // display error
            boardHandler.showNotification(
              data["error"].type,
              data["error"].title,
              data["error"].message
            );
          } else if (data["success"]) {
            // Mail has been sent with informations
            boardHandler.showNotification(
              data["success"].type,
              data["success"].title,
              data["success"].message
            );
          }
        },
      });
    });
  }
  static getPassCountry(city) {
    let code_pays;
    switch (city) {
      case "Afghanistan":
        code_pays = "AF";
        break;

      case "Afrique du Sud":
        code_pays = "ZA";
        break;

      case "Albanie":
        code_pays = "AL";
        break;

      case "Algérie":
        code_pays = "DZ";
        break;

      case "Allemagne":
        code_pays = "DE";
        break;

      case "Andorre":
        code_pays = "AD";
        break;

      case "Angola":
        code_pays = "AO";
        break;

      case "Antigua-et-Barbuda":
        code_pays = "AG";
        break;

      case "Arabie saoudite":
        code_pays = "SA";
        break;

      case "Argentine":
        code_pays = "AR";
        break;

      case "Arménie":
        code_pays = "AM";
        break;

      case "Australie":
        code_pays = "AU";
        break;

      case "Autriche":
        code_pays = "AT";
        break;

      case "Azerbaïdjan":
        code_pays = "AZ";
        break;

      case "Bahamas":
        code_pays = "BS";
        break;

      case "Bahreïn":
        code_pays = "BH";
        break;

      case "Bangladesh":
        code_pays = "BD";
        break;

      case "Barbade":
        code_pays = "BB";
        break;

      case "Belgique":
        code_pays = "BE";
        break;

      case "Belize":
        code_pays = "BZ";
        break;

      case "Bénin":
        code_pays = "BJ";
        break;

      case "Bhoutan":
        code_pays = "BT";
        break;

      case "Biélorussie":
        code_pays = "BY";
        break;

      case "Birmanie (Myanmar)":
        code_pays = "MM";
        break;

      case "Bolivie":
        code_pays = "BO";
        break;

      case "Bosnie-Herzégovine":
        code_pays = "BA";
        break;

      case "Botswana":
        code_pays = "BW";
        break;

      case "Brésil":
        code_pays = "BR";
        break;

      case "Brunei":
        code_pays = "BN";
        break;

      case "Bulgarie":
        code_pays = "BG";
        break;

      case "Burkina Faso":
        code_pays = "BF";
        break;

      case "Burundi":
        code_pays = "BI";
        break;

      case "Cambodge":
        code_pays = "KH";
        break;

      case "Cameroun":
        code_pays = "CM";
        break;

      case "Canada":
        code_pays = "CA";
        break;

      case "Cap-Vert":
        code_pays = "CV";
        break;

      case "Chili":
        code_pays = "CL";
        break;

      case "Chine":
        code_pays = "CN";
        break;

      case "Chypre":
        code_pays = "CY";
        break;

      case "Colombie":
        code_pays = "CO";
        break;

      case "Comores":
        code_pays = "KM";
        break;

      case "Congo-Brazzaville":
        code_pays = "CG";
        break;

      case "Congo-Kinshasa (RDC)":
        code_pays = "CD";
        break;

      case "Corée du Nord":
        code_pays = "KP";
        break;

      case "Corée du Sud":
        code_pays = "KR";
        break;

      case "Costa Rica":
        code_pays = "CR";
        break;

      case "Côte d'Ivoire":
        code_pays = "CI";
        break;

      case "Croatie":
        code_pays = "HR";
        break;

      case "Cuba":
        code_pays = "CU";
        break;

      case "Danemark":
        code_pays = "DK";
        break;

      case "Djibouti":
        code_pays = "DJ";
        break;

      case "Dominique":
        code_pays = "DM";
        break;

      case "Égypte":
        code_pays = "EG";
        break;

      case "Émirats arabes unis":
        code_pays = "AE";
        break;

      case "Équateur":
        code_pays = "EC";
        break;

      case "Érythrée":
        code_pays = "ER";
        break;

      case "Espagne":
        code_pays = "ES";
        break;

      case "Estonie":
        code_pays = "EE";
        break;

      case "Eswatini":
        code_pays = "SZ";
        break;

      case "États-Unis":
        code_pays = "US";
        break;

      case "Éthiopie":
        code_pays = "ET";
        break;

      case "Fidji":
        code_pays = "FJ";
        break;

      case "Finlande":
        code_pays = "FI";
        break;

      case "France":
        code_pays = "FR";
        break;

      case "Gabon":
        code_pays = "GA";
        break;

      case "Gambie":
        code_pays = "GM";
        break;

      case "Géorgie":
        code_pays = "GE";
        break;

      case "Ghana":
        code_pays = "GH";
        break;

      case "Grèce":
        code_pays = "GR";
        break;

      case "Grenade":
        code_pays = "GD";
        break;

      case "Guatemala":
        code_pays = "GT";
        break;

      case "Guinée":
        code_pays = "GN";
        break;

      case "Guinée-Bissau":
        code_pays = "GW";
        break;

      case "Guinée équatoriale":
        code_pays = "GQ";
        break;

      case "Guyana":
        code_pays = "GY";
        break;

      case "Haïti":
        code_pays = "HT";
        break;

      case "Honduras":
        code_pays = "HN";
        break;

      case "Hongrie":
        code_pays = "HU";
        break;

      case "Îles Cook":
        code_pays = "CK";
        break;

      case "Îles Marshall":
        code_pays = "MH";
        break;

      case "Îles Salomon":
        code_pays = "SB";
        break;

      case "Inde":
        code_pays = "IN";
        break;

      case "Indonésie":
        code_pays = "ID";
        break;

      case "Iran":
        code_pays = "IR";
        break;

      case "Iraq":
        code_pays = "IQ";
        break;

      case "Irlande":
        code_pays = "IE";
        break;

      case "Islande":
        code_pays = "IS";
        break;

      case "Israël":
        code_pays = "IL";
        break;

      case "Italie":
        code_pays = "IT";
        break;

      case "Jamaïque":
        code_pays = "JM";
        break;

      case "Japon":
        code_pays = "JP";
        break;

      case "Jordanie":
        code_pays = "JO";
        break;

      case "Kazakhstan":
        code_pays = "KZ";
        break;

      case "Kenya":
        code_pays = "KE";
        break;

      case "Kirghizistan":
        code_pays = "KG";
        break;

      case "Kiribati":
        code_pays = "KI";
        break;

      case "Koweït":
        code_pays = "KW";
        break;

      case "Laos":
        code_pays = "LA";
        break;

      case "Lesotho":
        code_pays = "LS";
        break;

      case "Lettonie":
        code_pays = "LV";
        break;

      case "Liban":
        code_pays = "LB";
        break;

      case "Liberia":
        code_pays = "LR";
        break;

      case "Libye":
        code_pays = "LY";
        break;

      case "Liechtenstein":
        code_pays = "LI";
        break;

      case "Lituanie":
        code_pays = "LT";
        break;

      case "Luxembourg":
        code_pays = "LU";
        break;

      case "Macédoine du Nord":
        code_pays = "MK";
        break;

      case "Madagascar":
        code_pays = "MG";
        break;

      case "Malaisie":
        code_pays = "MY";
        break;

      case "Malawi":
        code_pays = "MW";
        break;

      case "Maldives":
        code_pays = "MV";
        break;

      case "Mali":
        code_pays = "ML";
        break;

      case "Malte":
        code_pays = "MT";
        break;

      case "Maroc":
        code_pays = "MA";
        break;

      case "Maurice":
        code_pays = "MU";
        break;

      case "Mauritanie":
        code_pays = "MR";
        break;

      case "Mexique":
        code_pays = "MX";
        break;

      case "Micronésie":
        code_pays = "FM";
        break;

      case "Moldavie":
        code_pays = "MD";
        break;

      case "Monaco":
        code_pays = "MC";
        break;

      case "Mongolie":
        code_pays = "MN";
        break;

      case "Monténégro":
        code_pays = "ME";
        break;

      case "Mozambique":
        code_pays = "MZ";
        break;

      case "Namibie":
        code_pays = "NA";
        break;

      case "Nauru":
        code_pays = "NR";
        break;

      case "Népal":
        code_pays = "NP";
        break;

      case "Nicaragua":
        code_pays = "NI";
        break;

      case "Niger":
        code_pays = "NE";
        break;

      case "Nigéria":
        code_pays = "NG";
        break;

      case "Niue":
        code_pays = "NU";
        break;

      case "Norvège":
        code_pays = "NO";
        break;

      case "Nouvelle-Zélande":
        code_pays = "NZ";
        break;

      case "Oman":
        code_pays = "OM";
        break;

      case "Ouganda":
        code_pays = "UG";
        break;

      case "Ouzbékistan":
        code_pays = "UZ";
        break;

      case "Pakistan":
        code_pays = "PK";
        break;

      case "Palaos":
        code_pays = "PW";
        break;

      case "Palestine":
        code_pays = "PS";
        break;

      case "Panama":
        code_pays = "PA";
        break;

      case "Papouasie-Nouvelle-Guinée":
        code_pays = "PG";
        break;

      case "Paraguay":
        code_pays = "PY";
        break;

      case "Pays-Bas":
        code_pays = "NL";
        break;

      case "Pérou":
        code_pays = "PE";
        break;

      case "Philippines":
        code_pays = "PH";
        break;

      case "Pologne":
        code_pays = "PL";
        break;

      case "Portugal":
        code_pays = "PT";
        break;

      case "Qatar":
        code_pays = "QA";
        break;

      case "République centrafricaine":
        code_pays = "CF";
        break;

      case "République dominicaine":
        code_pays = "DO";
        break;

      case "République tchèque":
        code_pays = "CZ";
        break;

      case "Roumanie":
        code_pays = "RO";
        break;

      case "Royaume-Uni":
        code_pays = "GB";
        break;

      case "Russie":
        code_pays = "RU";
        break;

      case "Rwanda":
        code_pays = "RW";
        break;

      case "Saint-Christophe-et-Niévès":
        code_pays = "KN";
        break;

      case "Saint-Marin":
        code_pays = "SM";
        break;

      case "Saint-Vincent-et-les Grenadines":
        code_pays = "VC";
        break;

      case "Sainte-Lucie":
        code_pays = "LC";
        break;

      case "Salvador":
        code_pays = "SV";
        break;

      case "Samoa":
        code_pays = "WS";
        break;

      case "Sao Tomé-et-Principe":
        code_pays = "ST";
        break;

      case "Sénégal":
        code_pays = "SN";
        break;

      case "Serbie":
        code_pays = "RS";
        break;

      case "Seychelles":
        code_pays = "SC";
        break;

      case "Sierra Leone":
        code_pays = "SL";
        break;

      case "Singapour":
        code_pays = "SG";
        break;

      case "Slovaquie":
        code_pays = "SK";
        break;

      case "Slovénie":
        code_pays = "SI";
        break;

      case "Somalie":
        code_pays = "SO";
        break;

      case "Soudan":
        code_pays = "SD";
        break;

      case "Soudan du Sud":
        code_pays = "SS";
        break;

      case "Sri Lanka":
        code_pays = "LK";
        break;

      case "Suède":
        code_pays = "SE";
        break;

      case "Suisse":
        code_pays = "CH";
        break;

      case "Suriname":
        code_pays = "SR";
        break;

      case "Syrie":
        code_pays = "SY";
        break;

      case "Tadjikistan":
        code_pays = "TJ";
        break;

      case "Tanzanie":
        code_pays = "TZ";
        break;

      case "Tchad":
        code_pays = "TD";
        break;

      case "Thaïlande":
        code_pays = "TH";
        break;

      case "Timor-Leste":
        code_pays = "TL";
        break;

      case "Togo":
        code_pays = "TG";
        break;

      case "Tonga":
        code_pays = "TO";
        break;

      case "Trinité-et-Tobago":
        code_pays = "TT";
        break;

      case "Tunisie":
        code_pays = "TN";
        break;

      case "Turkménistan":
        code_pays = "TM";
        break;

      case "Turquie":
        code_pays = "TR";
        break;

      case "Tuvalu":
        code_pays = "TV";
        break;

      case "Ukraine":
        code_pays = "UA";
        break;

      case "Uruguay":
        code_pays = "UY";
        break;

      case "Vanuatu":
        code_pays = "VU";
        break;

      case "Vatican":
        code_pays = "VA";
        break;

      case "Venezuela":
        code_pays = "VE";
        break;

      case "Viêt Nam":
        code_pays = "VN";
        break;

      case "Yémen":
        code_pays = "YE";
        break;

      case "Zambie":
        code_pays = "ZM";
        break;

      case "Zimbabwe":
        code_pays = "ZW";
        break;
      case "default":
        code_pays = "FR";
        break;
    }
    return code_pays;
  }
  static createNewPasswordRecover() {
    $("#createRecoveryPassword").click(function (e) {
      e.preventDefault();
      $(".error_message").remove();
      var a = "/forgottenpwd/create-new-password";
      var postObj = new FormData();
      postObj.append("email", $("#email").val());
      postObj.append("password", $("#password").val());
      postObj.append("selector", $("#selector").val());
      postObj.append("validator", $("#validator").val());

      $.ajax({
        url: a,
        type: "POST",
        data: postObj,
        contentType: false,
        processData: false,
        success: function (data) {
          data = JSON.parse(data);
          // If something
          if (data["error"]) {
            // display error
            boardHandler.showNotification(
              data["error"].type,
              data["error"].title,
              data["error"].message
            );
          } else if (data["success"]) {
            // Mail has been sent with informations
            boardHandler.showNotification(
              data["success"].type,
              data["success"].title,
              data["success"].message
            );
          }
        },
      });
    });
  }

  static handleAuthUI() {
    var urlParams = new URLSearchParams(window.location.search);

    let shows = document.querySelectorAll(".password");

    shows.forEach((show) => {
      show.addEventListener("click", function () {
        console.log(show.previousElementSibling);
        switch (show.previousElementSibling.type) {
          case "password":
            show.previousElementSibling.type = "text";
            break;
          case "text":
            show.previousElementSibling.type = "password";
            break;
        }
      });
    });

    if (urlParams.has("signup")) {
      $(".login-form").hide();
      $(".signup-link").css("background-color", "#E57B3C");
      $(".signup-link h3").css("color", "white");
      $(".login-link").css("background-color", "white");
      $(".login-link > h3").css("color", "#E57B3C");
      $("#password-forget-link").hide();
    } else {
      $(".signup-form").hide();
      $(".login-link > h3").css("color", "white");
    }

    $(".login-link").click(function () {
      $(".login-form").show();
      $(".signup-form").hide();
      $(".login-link").css("background-color", "#E57B3C");
      $(".login-link h3").css("color", "white");
      $(".signup-link").css("background-color", "white");
      $(".signup-link h3").css("color", "#ED5800");
      $("#password-forget-link").show();
    });
    $(".signup-link").click(function () {
      $(".login-form").hide();
      $(".signup-form").show();
      $(".signup-link").css("background-color", "#E57B3C");
      $(".signup-link h3").css("color", "white");
      $(".login-link").css("background-color", "white");
      $(".login-link h3").css("color", "#ED5800");
      $("#password-forget-link").hide();
    });
  }
}
