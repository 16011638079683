/** @format */

import $ from "jquery";
import Chart from "chart.js/auto";
import mapBoxHandler from "./mapbox";
import boardHandler from "./board";
import AdminHandler from "./admin";

/**
 * Board form handling
 */

export default class AdminFilter {
	static handleFilters(key) {
		let button;
		switch (key) {
			case "parcels":
				button = document.getElementById("filterParcel");
				button.addEventListener("click", function (e) {
					e.preventDefault();

					var a = "/admin/filter-parcel";
					var postObj = new FormData();
					let parcel_status = document.getElementById("parcel_status");
					let parcel_id_colibriles = document.getElementById(
						"parcel_id_colibriles",
					);
					let client_email = document.getElementById("client_email");

					postObj.append("parcel_id_colibriles", parcel_id_colibriles.value);
					postObj.append("client_email", client_email.value);
					postObj.append(
						"parcel_status",
						parcel_status.options[parcel_status.selectedIndex].value,
					);

					if (
						postObj.get("parcel_status") == "" &&
						postObj.get("parcel_id_colibriles") == "" &&
						postObj.get("client_email") == ""
					) {
						boardHandler.showNotification(
							"error",
							"Erreur formulaire",
							"Veuillez renseigner au moin une informations pour la recherche",
						);
					} else {
						$.ajax({
							url: a,
							type: "POST",
							data: postObj,
							contentType: false,
							processData: false,
							success: function (data) {
								data = JSON.parse(data);
								// If something
								if (data["error"]) {
									// Send error message
									boardHandler.showNotification(
										data["error"].type,
										data["error"].title,
										data["error"].message,
									);
								} else if (data["success"]) {
									// Send success message
									boardHandler.showNotification(
										data["success"].type,
										data["success"].title,
										data["success"].message,
									);
									// SHOW USER
									AdminFilter.updateTable(data["success"], "parcel");
								}
							},
						});
					}
				});
				break;

			case "addresses":
				button = document.getElementById("filterAddressUser");
				button.addEventListener("click", function (e) {
					e.preventDefault();

					var a = "/admin/filter-address";
					var postObj = new FormData();

					postObj.append("email", $("#client_email").val());
					postObj.append("lastname", $("#client_lastname").val());

					if (postObj.get("email") == "" && postObj.get("lastname") == "") {
						boardHandler.showNotification(
							"error",
							"Erreur formulaire",
							"Veuillez renseigner au moin une informations pour la recherche",
						);
					} else {
						$.ajax({
							url: a,
							type: "POST",
							data: postObj,
							contentType: false,
							processData: false,
							success: function (data) {
								data = JSON.parse(data);

								// If something
								if (data["error"]) {
									// Send error message
									boardHandler.showNotification(
										data["error"].type,
										data["error"].title,
										data["error"].message,
									);
								} else if (data["success"]) {
									// Send success message
									boardHandler.showNotification(
										data["success"].type,
										data["success"].title,
										data["success"].message,
									);

									// SHOW USER
									AdminFilter.updateTable(data["success"], "address");
								}
							},
						});
					}
				});
				break;
			case "orders":
				button = document.getElementById("filterBill");
				button.addEventListener("click", function (e) {
					e.preventDefault();

					var a = "/admin/filter-orders";
					var postObj = new FormData();
					let order_status = document.getElementById("order_status");

					postObj.append("order_ref", $("#facture_id").val());
					postObj.append(
						"order_status",
						order_status.options[order_status.selectedIndex].value,
					);

					if (
						postObj.get("order_ref") == "" &&
						postObj.get("order_status") == ""
					) {
						boardHandler.showNotification(
							"error",
							"Erreur formulaire",
							"Veuillez renseigner au moin une informations pour la recherche",
						);
					} else {
						$.ajax({
							url: a,
							type: "POST",
							data: postObj,
							contentType: false,
							processData: false,
							success: function (data) {
								data = JSON.parse(data);

								// If something
								if (data["error"]) {
									// Send error message
									boardHandler.showNotification(
										data["error"].type,
										data["error"].title,
										data["error"].message,
									);
								} else if (data["success"]) {
									// Send success message
									boardHandler.showNotification(
										data["success"].type,
										data["success"].title,
										data["success"].message,
									);

									// SHOW USER
									AdminFilter.updateTable(data["success"], "orders");
								}
							},
						});
					}
				});
				break;

			case "users":
				button = document.getElementById("filterUser");
				button.addEventListener("click", function (e) {
					e.preventDefault();

					let inputs = {
						firstname: document.getElementById("client_firstname"),
						lastname: document.getElementById("client_lastname"),
						email: document.getElementById("client_email"),
					};

					var a = "/admin/filter-user";
					var postObj = new FormData();

					Object.keys(inputs).forEach((input) => {
						if (inputs[input].value) {
							postObj.append(input, inputs[input].value);
						}
					});

					if (
						!postObj.get("email") &&
						!postObj.get("firstname") &&
						!postObj.get("lastname")
					) {
						boardHandler.showNotification(
							"error",
							"Erreur formulaire",
							"Veuillez renseigner au moin une informations pour la recherche",
						);
					} else {
						$.ajax({
							url: a,
							type: "POST",
							data: postObj,
							contentType: false,
							processData: false,
							success: function (data) {
								data = JSON.parse(data);
								// If something
								if (data["error"]) {
									// Send error message
									boardHandler.showNotification(
										data["error"].type,
										data["error"].title,
										data["error"].message,
									);
								} else if (data["success"]) {
									// Send success message
									boardHandler.showNotification(
										data["success"].type,
										data["success"].title,
										data["success"].message,
									);

									// SHOW USER
									AdminFilter.updateTable(data["success"], "users");
								}
							},
						});
					}
				});
				break;

			default:
				break;
		}

		$("#filterParcelUser").click(function (e) {
			e.preventDefault();
			$(".error_message").remove();
			var a = "/admin/filter-parcel/user";
			var postObj = new FormData();
			postObj.append("email", $("#client_email").val());

			$.ajax({
				url: a,
				type: "POST",
				data: postObj,
				contentType: false,
				processData: false,
				success: function (data) {
					data = JSON.parse(data);

					// If something
					if (data["error"]) {
						// Send error message
						boardHandler.showNotification(
							data["error"].type,
							data["error"].title,
							data["error"].message,
						);
					} else if (data["success"]) {
						// Send success message
						//boardHandler.showNotification(data['success'].type, data['success'].title, data['success'].message);
						// SHOW PARCELS
					}
				},
			});
		});
	}

	static updateTable(data, key) {
		let table, entries, is_main;
		switch (key) {
			case "users":
				table = document.getElementById("content-users");
				entries = document.querySelectorAll("#content-users tr");
				// Delete entries in table
				entries.forEach((entry) => {
					entry.remove();
				});

				data.user.forEach((content, index) => {
					const event = new Date(content.created_at),
						options = { year: "numeric", month: "long", day: "numeric" },
						date = event.toLocaleDateString("fr-FR", options);
					let line = `
                    <tr>
                        <td>
                            <a class=\"fw-semibold orange user-id\" href=\"javascript:void(0)\">${content.id}</a>
                        </td>
                        <td>
                            <a class=\"fw-semibold orange user-id_colibriles\" href=\"javascript:void(0)\">${content.id_colibriles}</a>
                        </td>
                        <td class=\"d-xl-table-cell\">
                            <a class=\"fw-semibold user-firstname\" href=\"javascript:void(0)\">${content.firstname}</a>
                        </td>
                        <td>
                            <span class=\"fs-xs fw-semibold text-content user-lastname\">${content.lastname}</span>
                        </td>
                        <td class=\"d-xl-table-cell\">
                            <a class=\"fw-semibold user-email\" href=\"javascript:void(0)\">${content.email}</a>
                        </td>
                        <td class=\"d-xl-table-cell\">
                            <a class=\"fw-semibold user-email\" href=\"javascript:void(0)\">${content.phone}</a>
                        </td>
                        <td class=\"d-sm-table-cell text-end parcel-group-date\">
                            <strong>${date}</strong>
                        </td>
                        <td class=\"w-50\"><div class=\"block\"></div><a id=\"${content.id}\" class=\"edit\"><img src=\"/public/assets/svg/edit.svg\"></a></td>
                        <td class=\"w-50\"><div class=\"block\"></div><a id=\"${content.id}\" class=\"delete\"><img src=\"/public/assets/svg/error.svg\"></a></td>

                    </tr>`;
					table.innerHTML += line;
				});

				AdminHandler.eventButton("delete", "users");
				AdminHandler.eventButton("edit", "users");

				break;
			case "orders":
				table = document.getElementById("content-users");
				entries = document.querySelectorAll("#content-users tr");
				// Delete entries in table
				entries.forEach((entry) => {
					entry.remove();
				});

				data.orders.forEach((content, index) => {
					const event = new Date(content.created_at),
						options = { year: "numeric", month: "long", day: "numeric" },
						date = event.toLocaleDateString("fr-FR", options);
					let line = `
                    <tr>
                        <td><a class="fw-semibold id">${content.order_ref}</a></td>
                        <td class="d-xl-table-cell"><a class="fw-semibold orange country" href="javascript:void(0)">${data.payments[index].paypal_id}</a></td>
                        <td class="d-sm-table-cell fw-semibold text-muted zipcode text-end">${content.total_price} €</td>
                        <td class="d-sm-table-cell fw-semibold text-muted text-end"><b>${data.parcels[index].id_colibriles}</b></td>
                        <td><span class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-info-light parcel-status text-info">${content.status}</span></td>
                        <td class="d-sm-table-cell fw-semibold text-muted text-end">${date}</td>
                        <td class="w-50"><a href="/admin/bill/${content.id}"><img src="/public/assets/svg/search.svg"></a></td>
                        <td class="w-50"><div class="block"></div><a id="${content.id}" class="edit groups"><img src="/public/assets/svg/edit.svg"></a></td>
                        <td class="w-50"><a id="${content.id}" class="delete groups"><img src="/public/assets/svg/error.svg"></a></td>
                    </tr>`;
					table.innerHTML += line;
				});

				AdminHandler.eventButton("delete", "orders");
				AdminHandler.eventButton("edit", "orders");

				break;
			case "address":
				table = document.getElementById("content-users");
				entries = document.querySelectorAll("#content-users tr");
				// Delete entries in table
				entries.forEach((entry) => {
					entry.remove();
				});

				data.addresses.forEach((address, index) => {
					address.forEach((content) => {
						switch (content.is_main) {
							case "0":
								is_main = "Secondaire";
								break;
							case "1":
								is_main = "Principale";
								break;
							default:
								is_main = "Secondaire";
								break;
						}
						const event = new Date(content.created_at),
							options = { year: "numeric", month: "long", day: "numeric" },
							date = event.toLocaleDateString("fr-FR", options);
						let line = `
                        <tr>
                            <td>
                                <a class=\"fw-semibold orange address-id\" href=\"javascript:void(0)\">${content.id}</a>
                            </td>
                            <td>
                                <a class=\"fw-semibold orange address-full_address\" href=\"javascript:void(0)\">${data.user[index].lastname}</a>
                            </td>
                            <td>
                                <a class=\"fw-semibold orange address-full_address\" href=\"javascript:void(0)\">${data.user[index].firstname}</a>
                            </td>
                            <td>
                                <a class=\"fw-semibold orange address-full_address\" href=\"javascript:void(0)\">${content.address_full}</a>
                            </td>
                            <td class=\"d-xl-table-cell\">
                                <a class=\"fw-semibold address-city\" href=\"javascript:void(0)\">${content.city}</a>
                            </td>
                            <td>
                                <span class=\"fs-xs fw-semibold text-content address-zipcode\">${content.zip}</span>
                            </td>
                            <td class=\"d-xl-table-cell\">
                                <a class=\"fw-semibold address-country\" href=\"javascript:void(0)\">${content.country}</a>
                            </td>
                            <td class=\"d-sm-table-cell text-end address-type\">
                                <strong>${is_main}</strong>
                            </td>
                            <td class=\"d-xl-table-cell\">
                                <a class=\"fw-semibold address-phone\" href=\"javascript:void(0)\">${content.phone}</a>
                            </td>
                            <td class=\"w-50\"><div class=\"block\"></div><a id=\"${content.id}\" class=\"edit\"><img src=\"/public/assets/svg/edit.svg\"></a></td>
                            <td class=\"w-50\"><div class=\"block\"></div><a id=\"${content.id}\" class=\"delete\"><img src=\"/public/assets/svg/error.svg\"></a></td>
    
                        </tr>`;
						table.innerHTML += line;
					});
				});

				AdminHandler.eventButton("delete", "addresses");
				AdminHandler.eventButton("edit", "addresses");

				break;
			case "parcel":
				table = document.getElementById("content-users");
				entries = document.querySelectorAll("#content-users tr");
				// Delete entries in table
				entries.forEach((entry) => {
					entry.remove();
				});

				data.content.forEach((parcel, index) => {
					const event = new Date(parcel.created_at),
						options = { year: "numeric", month: "long", day: "numeric" },
						date = event.toLocaleDateString("fr-FR", options);

					let line = `
                        <tr>
                            <td>
                                <a class="fw-semibold orange parcel-id" href="javascript:void(0)">${parcel.id_colibriles}</a>

                            </td>
                            <td class="d-xl-table-cell">
                                <a class="fw-semibold parcel-name-cart" href="javascript:void(0)">${parcel.name}</a>
                            </td>
                            <td class="d-xl-table-cell">
                                <a class="fw-semibold parcel-name-cart" href="javascript:void(0)">${parcel.content}</a>
                            </td>
                            <td class="d-xl-table-cell">
                                <a class="fw-semibold parcel-name-cart" href="javascript:void(0)">${parcel.store}</a>
                            </td>
                            <td>
                                <span class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-info-light parcel-status text-info">${parcel.status}</span>
                            </td>
                            <td class="d-sm-table-cell text-end">
                                <strong>${date}</strong>
                            </td>
                            <td class="w-50"><div class="block"></div><a id="${parcel.id}" href="/download-bill-admin?id=${parcel.id}" class=""><img src="/public/assets/svg/search.svg"></a></td>
                            <td class="w-50"><div class="block"></div><a id="${parcel.id}" class="edit"><img src="/public/assets/svg/edit.svg"></a></td>
                            <td class="w-50"><a id="${parcel.id}" class="delete"><img src="/public/assets/svg/error.svg"></a></td>
                        </tr>`;
					table.innerHTML += line;
				});

				AdminHandler.eventButton("delete", "parcels");
				AdminHandler.eventButton("edit", "parcels");

				break;

			default:
				break;
		}
	}
}
