import $, { post } from "jquery";
import Chart from 'chart.js/auto';
import mapBoxHandler from './mapbox';
import boardHandler from "./board";

/**
 * Board form handling
 */

export default class AdminHandler {

    static eventButton(key, target) {
        switch (key) {
            case "add": 
                let addButton = document.getElementById('parcelCreateButton');
                let add_id_colibriles = document.getElementById('add_id_colibriles');
                let form_add = document.getElementById('form_add');
                let add_name = document.getElementById('add_name');
                let add_content = document.getElementById('add_content');
                let add_commentary = document.getElementById('add_commentary');
                let add_store = document.getElementById('add_store');
                let add_width = document.getElementById('add_width');
                let add_height = document.getElementById('add_height');
                let add_depth = document.getElementById('add_depth');
                let add_weight = document.getElementById('add_weight');
                let add_parcel_verification_bill = document.getElementById('add_parcel_verification_bill');
                let add_status = document.getElementById('add_status');
                let add_identification = document.getElementById('add_identification');
                let add_verification = document.getElementById('add_verification');
                let open_button = document.getElementById('add-parcel');

                open_button.addEventListener('click', function() {
                    form_add.style.display = "block";
                })

                addButton.addEventListener('click', function(e) {

                    e.preventDefault();
                    let postObj = new FormData();
                    let a = "/create-parcel-admin";  
                     
                    postObj.append('id_colibriles', add_id_colibriles.value);
                    postObj.append('name', add_name.value);
                    postObj.append('content', add_content.value);
                    postObj.append('commentary', add_commentary.value);
                    postObj.append('store', add_store.value);
                    postObj.append('width', add_width.value);
                    postObj.append('height', add_height.value);
                    postObj.append('depth', add_depth.value);
                    postObj.append('weight', add_weight.value);
                    

                    postObj.append('bad_identification', add_identification.options[add_identification.selectedIndex].value);
                    postObj.append('is_checked', add_verification.options[add_verification.selectedIndex].value);
                    postObj.append('status', add_status.options[add_status.selectedIndex].value);
                    (add_parcel_verification_bill.files[0] ? postObj.append('parcel_verification_bill', add_parcel_verification_bill.files[0]) : "");


                    $.ajax({
                        url: a,
                        type: 'POST',
                        data: postObj,
                        contentType: false,
                        processData: false,
                        success: function(data) {
                            data = JSON.parse(data);
                            if(data['error']) {
                                boardHandler.showNotification(data['error'].type, data['error'].title, data['error'].message);
                            }
                            else if(data['success']) {
                                boardHandler.showNotification(data['success'].type, data['success'].title, data['success'].message);
                            }
                        }
                    })
                })
                
                break;
            case "edit":
                let edits = document.querySelectorAll('.edit'),
                widget = document.getElementById('form_edit');
                let button_node, edit_form, edit_button;

              
                switch (target) {
                    case "users":
                        let edit_firstname = document.getElementById('edit_firstname'),
                        edit_lastname = document.getElementById('edit_lastname'),
                        edit_email = document.getElementById('edit_email'),
                        edit_password = document.getElementById('edit_password'),
                        edit_phone = document.getElementById('edit_phone');

                        break;
                    case "addresses":
                        let edit_full_address = document.getElementById('edit_full_address'),
                        additional = document.getElementById('additional'),
                        edit_city = document.getElementById('edit_city'),
                        edit_zipcode = document.getElementById('edit_zipcode'),
                        edit_country = document.getElementById('edit_country'),
                        edit_country_code = document.getElementById('edit_country_code'),
                        edit_address_type = document.getElementById('edit_address_type'),
                        edit_phone_address = document.getElementById('edit_phone_address');

                        break;
                    case "parcels":
                        let edit_name = document.getElementById('edit_name'),
                        edit_content = document.getElementById('edit_content'),
                        edit_commentary = document.getElementById('edit_commentary'),
                        edit_store = document.getElementById('edit_store'),
                        edit_parcel_verification_bill = document.getElementById('edit_parcel_verification_bill'),
                        edit_status = document.getElementById('edit_status'),
                        edit_width = document.getElementById('edit_width'),
                        edit_height = document.getElementById('edit_height'),
                        edit_depth = document.getElementById('edit_depth'),
                        edit_weight = document.getElementById('edit_weight'),
                        edit_identification = document.getElementById('edit_identification'),
                        edit_verification = document.getElementById('edit_verification');

                        break;
                    case "orders":
                        let edit_address = document.getElementById('edit_address_type'),
                        edit_price = document.getElementById('edit_price'),
                        edit_commentary_admin = document.getElementById('edit_commentary'),
                        edit_status_admin = document.getElementById('edit_statut');
                    
                        break;
                }
                

                edits.forEach((edit, index) => {
                    let parent = edit.parentElement;
                    let node = edit.cloneNode(true);
                    let a, user, address, parcel, grouped, orders;
                    

                    parent.appendChild(node);
                    edit.remove();

                    
                    node.addEventListener('click', function() {
                        window.location.replace(location.pathname + "#form_edit");
                        switch (target) {
                            case "users":
                                edit_button = document.getElementById('editUserButton');
        
                                break;
                            case "addresses":
                                edit_button = document.getElementById('editAddressButton');
                                break;
                            case "parcels":
                                edit_button = document.getElementById('editParcelButton');
                                break;
                            case "orders":
                                edit_button = document.getElementById('editOrderButton');
                                break;
                        }
                        button_node = edit_button.cloneNode(true);
                        edit_form = document.querySelector('.edit_form');
                        edit_form.appendChild(button_node);
                        edit_button.remove();
                        
                        widget.style.display = "flex";
                        widget.classList.add('active');
                        let postObj = new FormData();

                        switch (target) {
                            case "users":
                                a = "/admin/get-user";   
                                postObj.append('user_id', node.id);
                                break;
                            case "addresses":
                                a = "/admin/get-address";
                                postObj.append('address_id', node.id);
                                break;
                            case "parcels":
                                a = "/admin/get-parcel";
                                (edit.classList.contains('groups') ? postObj.append('is_grouped', 1) : postObj.append('is_grouped', 0))
                                postObj.append('parcel_id', node.id);
                                
                                break;
                            case "orders":
                                a = "/admin/get-order";
                                postObj.append('order_id', node.id);
                                
                                break;
                        }
                        $.ajax({
                            url: a,
                            type: 'POST',
                            data: postObj,
                            contentType: false,
                            processData: false,
                            success: function(data) {
                                data = JSON.parse(data);
                                if(data['error']) {
                                    boardHandler.showNotification(data['error'].type, data['error'].title, data['error'].message);
                                }
                                else if(data['success']) {
                                    switch (target) {
                                        case "users":
                                            user = data['success'].user;
                                            edit_firstname.value = user.firstname;
                                            edit_lastname.value = user.lastname;
                                            edit_email.value = user.email;
                                            edit_password.value = user.password;
                                            edit_phone.value = '0' + user.phone;
                                            break;
                                        case "addresses":
                                            address = data['success'].address;
                                            let is_main = parseInt(address.is_main);
                                            edit_full_address.value = address.address_full;
                                            additional.value = address.additional
                                            edit_city.value = address.city;
                                            edit_zipcode.value = address.zip;
                                            edit_country.value = address.country;
                                            edit_country_code.value = address.country_code;

                                            edit_phone_address.value = '0' + address.phone;
                                            edit_address_type.options[is_main].selected = true;

                                            break;
                                         case "parcels":
                                            parcel = data['success'].parcel;
                                            
                                            edit_identification.options[parseInt(parcel.bad_identification)].selected = true;
                                            edit_verification.options[parseInt(parcel.is_checked)].selected = true;

                                            for (let i = 0; i < edit_status.options.length; i++) {
                                                let option = edit_status.options[i];
                                                if(parcel.status == edit_status.options[i].value){
                                                    edit_status.options[i].selected = true
                                                }
                                            }
                                            
                                            if(edit.classList.contains('groups')) {
                                                edit_name.value = parcel.name;
                                                edit_width.value = parcel.width;
                                                edit_height.value = parcel.height;
                                                edit_depth.value = parcel.depth;
                                                edit_weight.value = parcel.weight;
                                                edit_content.parentElement.style.display = "none";
                                                edit_commentary.parentElement.style.display = "none";
                                                edit_store.parentElement.style.display = "none";

                                                edit_parcel_verification_bill.parentElement.style.display = "none";
                                            } else {
                                                edit_name.value = parcel.name;
                                                edit_content.value = parcel.content;
                                                edit_commentary.value = parcel.commentary;
                                                edit_store.value = parcel.store;
                                                edit_width.value = parcel.width;
                                                edit_height.value = parcel.height;
                                                edit_depth.value = parcel.depth;
                                                edit_weight.value = parcel.weight;
                                                edit_content.parentElement.style.display = "block";
                                                edit_commentary.parentElement.style.display = "block";
                                                edit_store.parentElement.style.display = "block";
                                                edit_parcel_verification_bill.parentElement.style.display = "block";

                                            }
                                            break;
                                        case "orders":
                                            orders = data['success'].order;
                                            address =  data['success'].addresses;

                                            let selected, optionAddress, option,
                                            edit_address = document.getElementById('edit_address_type'),
                                            edit_price = document.getElementById('edit_price'),
                                            edit_commentary_admin = document.getElementById('edit_commentary'),
                                            edit_status_admin = document.getElementById('edit_statut');
                                            // 
                                            address.forEach(one => {
                                                optionAddress = `<option value="${one.id}">${one.address_full}</option>`;
                                                edit_address.innerHTML += optionAddress;
                                            });


                                            for (let i = 0; i < edit_address.options.length; i++) {
                                                option = edit_address.options[i];
                                                if(parseInt(orders.delivery_address_id) == edit_address.options[i].value){
                                                    edit_address.options[i].selected = true
                                                }
                                            }


                                            for (let i = 0; i < edit_status_admin.options.length; i++) {
                                                option = edit_status_admin.options[i];
                                                if(orders.status == edit_status_admin.options[i].value){
                                                    edit_status_admin.options[i].selected = true
                                                }
                                            }

                                            
                                            edit_price.value = orders.total_price;
                                            edit_commentary_admin.value = orders.commentary;
                                            break;
                                    }

                                    button_node.addEventListener('click', function(e) {
                                        e.preventDefault();
                                        let a;
                                        let postObj = new FormData();
                                        // PRENDRE L'EDIT USER
                                        switch (target) {
                                            case "users":
                                                a = "/admin/edit-user";
                                                postObj.append('user_id', node.id);
                                                postObj.append('firstname', edit_firstname.value);
                                                postObj.append('lastname', edit_lastname.value);
                                                postObj.append('email', edit_email.value);
                                                postObj.append('password', edit_password.value);
                                                postObj.append('phone', edit_phone.value);
                                                break;
                                            case "addresses":
                                                a = "/admin/edit-address";
                                                postObj.append('address_id', node.id);
                                                postObj.append('address', edit_full_address.value);
                                                postObj.append('additional', additional.value);
                                                postObj.append('city', edit_city.value);
                                                postObj.append('zipcode', edit_zipcode.value);
                                                postObj.append('country', edit_country.value);
                                                postObj.append('country_code', edit_country_code.value);
                                                
                                                postObj.append('phone', edit_phone_address.value);
                                                postObj.append('is_main', edit_address_type.value);
                                                break;
                                            case "parcels":
                                                a = "/admin/edit-parcel";

                                                postObj.append('bad_identification', edit_identification.options[edit_identification.selectedIndex].value);
                                                postObj.append('is_checked', edit_verification.options[edit_verification.selectedIndex].value);
                                                if(edit.classList.contains('groups')) {
                                                    postObj.append('is_grouped', 1);
                                                    postObj.append('parcel_id', node.id);
                                                    postObj.append('name', edit_name.value);
                                                    postObj.append('width', edit_width.value);
                                                    postObj.append('height', edit_height.value);
                                                    postObj.append('depth', edit_depth.value);
                                                    postObj.append('weight', edit_weight.value);
                                                    postObj.append('status', edit_status.options[edit_status.selectedIndex].value);
                                                } else {
                                                    (edit_parcel_verification_bill.files[0] ? postObj.append('parcel_verification_bill', edit_parcel_verification_bill.files[0]) : "");
                                                    postObj.append('is_grouped', 0);
                                                    postObj.append('parcel_id', node.id);
                                                    postObj.append('name', edit_name.value);
                                                    postObj.append('content', edit_content.value);
                                                    postObj.append('commentary', edit_commentary.value);
                                                    postObj.append('store', edit_store.value);
                                                    postObj.append('width', edit_width.value);
                                                    postObj.append('height', edit_height.value);
                                                    postObj.append('depth', edit_depth.value);
                                                    postObj.append('weight', edit_weight.value);
                                                    postObj.append('status', edit_status.options[edit_status.selectedIndex].value);
                                                }
                                                break;
                                            case "orders":
                                                a = "/admin/edit-order";
                                                let edit_address = document.getElementById('edit_address_type'),
                                                edit_price = document.getElementById('edit_price'),
                                                edit_commentary_admin = document.getElementById('edit_commentary'),
                                                edit_status_admin = document.getElementById('edit_statut');


                                                
                                                postObj.append('order_id', node.id);
                                                postObj.append('delivery_address_id', edit_address.options[edit_address.selectedIndex].value);
                                                postObj.append('status', edit_status_admin.options[edit_status_admin.selectedIndex].value);
                                                postObj.append('total_price', edit_price.value);
                                                postObj.append('commentary', edit_commentary_admin.value);

                                                break;
                                        }
                                    
                                        
                                        $.ajax({
                                            url: a,
                                            type: 'POST',
                                            data: postObj,
                                            contentType: false,
                                            processData: false,
                                            success: function(data) {
                                                data = JSON.parse(data);
                                                if(data['error']) {
                                                    boardHandler.showNotification(data['error'].type, data['error'].title, data['error'].message);
                                                } else if(data['success']) {
                                                    boardHandler.showNotification(data['success'].type, data['success'].title, data['success'].message);
                                                    setTimeout(() => {
                                                        location.reload();
                                                    }, 4000)
                                                }
                                            }
                                        });
                                    });
                                }
                            }
                        });
                    })
                });
                break;
            case "delete":
                
                let bens = document.querySelectorAll('.delete');
                let grouped;
                bens.forEach((ben, index) => {
                    
                    let parent = ben.parentElement,
                    node = ben.cloneNode(true);
                    parent.appendChild(node);
                    ben.remove();

                    node.addEventListener('click', function() {
                        node.parentElement.parentElement.innerHTML += `
                        <td class=\"comfirmed\">
                            <div> 
                                <div>
                                    <h4>Voulez vous vraiment supprimer cette ligne ?<h4>
                                </div>
                                <div>
                                    <button class=\"button error\">Non</button>
                                    <button class=\"button success\">Oui</button>
                                </div>
                            </div>
                        </td>`;

                        let comfirmed = document.querySelector('.comfirmed');
                        let buttonComfirmed = document.querySelectorAll('.button.success');
                        let buttonRefused = document.querySelectorAll('.button.error');


                        // ADD COMFIRME MODAL


                        comfirmed.style.display = "flex";
                        buttonRefused.forEach(button => {
                            button.addEventListener('click', function() {
                                button.parentElement.parentElement.parentElement.remove();
                                boardHandler.eventButton(3, target, target);
                            })
                        });

                        buttonComfirmed.forEach(button => {
                            button.addEventListener('click', function() {
                                let a;
                                let postObj = new FormData();
                                switch (target) {
                                    case "addresses":
                                        a = "/admin/delete-address";
                                        postObj.append('id', node.id);

                                        break;
                                    case "users":
                                        a = "/admin/delete-user";
                                        postObj.append('id', node.id);

                                        break;
                                    case "orders":
                                        a = "/admin/delete-orders";
                                        postObj.append('id', node.id);

                                        break;
                                    case "parcels":
                                        a = "/admin/delete-parcels";
                                        (node.classList.contains('groups') ? grouped = 1 : grouped = 0);

                                        postObj.append('id', node.id);
                                        postObj.append('is_group', grouped);

                                        break;
                                    default:
                                        break;
                                }

                                $.ajax({
                                    url: a,
                                    type: 'POST',
                                    data: postObj,
                                    contentType: false,
                                    processData: false,
                                    success: function(data) {
                                        data = JSON.parse(data);
                                        // If something
                                        if(data['error']) {
                                            // display error
                                            boardHandler.showNotification(data['error'].type, data['error'].title, data['error'].message);
                                        }
                                        else if(data['success']) {
                                            // Delete User html
                                            document.getElementById(node.id).parentElement.parentElement.remove();

                                            // Update button
                                            boardHandler.eventButton("delete");

                                            // Send message
                                            boardHandler.showNotification(data['success'].type, data['success'].title, data['success'].message);
                                        }
                                    }
                                });
                            })
                        });
                    })
                });
                break;
        }
    }
}