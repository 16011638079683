import $ from "jquery";
import boardHandler from "./board";

export default function contactFormHandler(){
    $("#contactFormButton").click(function(e) {
        e.preventDefault()
        $(".error_message").remove();
        var a = "/contact-us";
        var postObj = new FormData();
        postObj.append('email', $("#email").val());
        postObj.append('subject', $("#subject").val());
        postObj.append('content', $("#content").val());

        
        if($("#email").val() != '' && $("#subject").val() != '' &&  $("#content").val() != '') {
            $.ajax({
                url: a,
                type: 'POST',
                data: postObj,
                contentType: false,
                processData: false,
                success: function(data) {
                    data = JSON.parse(data);
                    // If something
                    if(data['error']){
                        boardHandler.showNotification(data['error'].type, data['error'].title, data['error'].message);
                    }
                    else if(data['success']){
                        boardHandler.showNotification(data['success'].type, data['success'].title, data['success'].message);

                    }
                }
            });
        }
        else {
            boardHandler.showNotification("error", "Erreur formulaire", "Informations manquantes dans le formulaire");
        }
    });
}


