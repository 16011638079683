import $ from "jquery";
import { forEach, once } from "lodash";
import boardHandler from "../board";

export default class checkoutHandler {

    static handleSelectAddress() {
        let select = document.getElementById('address');
        select.addEventListener('change', function() {
            let a = "/get-select-address";
            let postObj = new FormData();
            postObj.append("user_address_id", select.options[ select.selectedIndex ].value);

            $.ajax({
                url: a,
                type: "POST",
                data: postObj,
                contentType: false,
                processData: false,
                success: function(data) {
                    data = JSON.parse(data);
                    // If something
                    if(data['error']){
                        //display error
                        // boardHandler.showNotification(
                        //     data["error"].type,
                        //     data["error"].title,
                        //     data["error"].message
                        // );
                    }
                    if(data['success']){
                        location.reload();
                    }
                    // Else its good
                    // User has been added
                }
            });

        })
    }
    static getDataForCheckout() {
        let button = document.getElementById('button-checkout');
        let cards = document.querySelectorAll('.offers > div');
        let verification_check = "unchecked"
        button.addEventListener('click', function(){
            if(document.getElementById("verification").checked == true){
                verification_check = "checked";
            }
            cards.forEach((card, index) => {
                if(card.classList == "active") {
                
                    let a = "/getCheckoutOffer";
                    let postObj = new FormData();
                    postObj.append("cart_offer_id", offers[index].id);
                    postObj.append("verification_check", verification_check);

                    $.ajax({
                        url: a,
                        type: "POST",
                        data: postObj,
                        contentType: false,
                        processData: false,
                        success: function(data) {
                            data = JSON.parse(data);
                            // If something
                            if(data['error']){
                                //display error
                                boardHandler.showNotification(
                                    data["error"].type,
                                    data["error"].title,
                                    data["error"].message
                                );
                            }
                            if(data['success']){
                                boardHandler.showNotification(
                                    data["success"].type,
                                    data["success"].title,
                                    data["success"].message
                                );
                                
                                window.location.href = "/checkout"
                            }
                            // Else its good
                            // User has been added
                        }
                    });
                }
            });
        })
    }
    static handleCheckboxVerification(){
        let checkbox = document.querySelector("#verification");
        let ttc = document.querySelector('.ttc');
        
        checkbox.addEventListener("change", (e) => {
            if (e.target.checked) {
                let oldPrice = ttc.innerHTML.replace(/[^\d.-]/g, '');
                let newPrice = parseFloat(oldPrice) + 3.00
                ttc.innerHTML = newPrice.toFixed(2) + " €"
            } else {
                let oldPrice = ttc.innerHTML.replace(/[^\d.-]/g, '');
                let newPrice = parseFloat(oldPrice) - 3.00
                ttc.innerHTML = newPrice.toFixed(2) + " €"
            }
        });
    }
}
