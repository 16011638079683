import $ from "jquery";
import Chart from 'chart.js/auto';
import mapBoxHandler from './mapbox';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

/**
 * Board form handling
 */

export default class boardHandler {


    static createPDFfromHTML() {
        let button = document.querySelector('#s_dowload');
        button.addEventListener("click", () => {
            button.style.display = "none";
          var HTML_Width = $(".bill").width();
          var HTML_Height = $(".bill").height();
          var top_left_margin = 15;
          var PDF_Width = HTML_Width + (top_left_margin * 2);
          var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
          var canvas_image_width = HTML_Width;
          var canvas_image_height = HTML_Height;
      
          var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      
          html2canvas($(".bill")[0]).then(function (canvas) {
                var imgData = canvas.toDataURL("image/jpeg", 1.0);
                var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
                pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
                for (var i = 1; i <= totalPDFPages; i++) { 
                    pdf.addPage(PDF_Width, PDF_Height);
                    pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
                }
                pdf.save("votre-facture.pdf");
                button.style.display = "block";

          });
        });
      }
    static calculHeightWidget() {
        let widgetLarge = document.querySelector('.x-large');
        let widgetMedium = document.querySelector('.h-medium');
        let widgetSmall = document.querySelectorAll('.medium');

        widgetLarge.style.height = (widgetMedium.clientHeight / 2) + "px";

        widgetSmall.forEach(small => {
            small.style.height = (widgetMedium.clientHeight / 2) + "px";
        });
    }
    static copyAddress() {
        let copyButton = document.getElementById('copy-address');
        var address = "137, avenue Anatole Choisy-le-Roi France";
        copyButton.addEventListener('click', function() {
            navigator.clipboard.writeText(address).then(function() {
                // ! Send notification
                boardHandler.showNotification("success","Adresse copié !", "L'adresse a été copié avec succès.");
            })
        })
    }

    static delay(milliseconds){
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }

    static initAutocomplete(){
        const autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('address_full'),
            {
                fields: ["address_component"],
            }
        )
        autocomplete.addListener('place_changed', onPlaceChanged)
    
        function onPlaceChanged(){
            var place = autocomplete.getPlace()
            $('.form-disabled').prop("disabled", false);
            let address_full;
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
    
                if (addressType == "street_number") {
                    address_full = place.address_components[i].long_name
                }
                if (addressType == "route") {
                    address_full += " " +  place.address_components[i].long_name
                    $("#address_full").val(address_full)
                }
                if (addressType == "locality") {
                    $("#city").val(place.address_components[i].long_name)
                }
                if (addressType == "postal_code") {
                    $("#zipcode").val(place.address_components[i].long_name);
                }
                if (addressType == "country") {
                    $("#country").val(place.address_components[i].long_name);
                    $("#country_code").val(place.address_components[i].short_name)
                }
            }
        }
    }
    static initAutocompleteEdit(){
        const autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('edit_full_address'),
            {
                fields: ["address_component"],
            }
        )
        autocomplete.addListener('place_changed', onPlaceChanged)
    
        function onPlaceChanged(){
            var place = autocomplete.getPlace()
            
            let address_full;
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
    
                if (addressType == "street_number") {
                    address_full = place.address_components[i].long_name
                }
                if (addressType == "route") {
                    address_full += " " +  place.address_components[i].long_name
                    $("#edit_full_address").val(address_full)
                }
                if (addressType == "locality") {
                    $("#edit_city").val(place.address_components[i].long_name)
                }
                if (addressType == "postal_code") {
                    $("#edit_zipcode").val(place.address_components[i].long_name);
                }
                if (addressType == "country") {
                    $("#edit_country").val(place.address_components[i].long_name);
                    $("#edit_country_code").val(place.address_components[i].short_name)
                }
            }
        }
    }
    static addFormHandler(key){
        switch (key) {
            case 1:
                $("#addressFormButton").click(function(e) {
                    e.preventDefault()
                    $(".error_message").remove();
                    var a = "/add_address";
                    var postObj = new FormData();
                    postObj.append('address_full', $("#address_full").val());
                    postObj.append('additional', $("#add_additional_address").val());
                    postObj.append('city', $("#city").val());
                    postObj.append('zipcode', $("#zipcode").val());
                    postObj.append('country', $("#country").val());
                    postObj.append('country_code', $("#country_code").val());
                    postObj.append('address_type', $("#address_type").val());
                    postObj.append('phone', $("#phone").val());
            
            
                
                    $.ajax({
                        url: a,
                        type: 'POST',
                        data: postObj,
                        contentType: false,
                        processData: false,
                        success: function(data) {
                            data = JSON.parse(data);
                            // If something
                            if(data['error']){
                                // Send error message
                                boardHandler.showNotification(data['error'].type, data['error'].title, data['error'].message);
                            }
                            else if(data['success']){
                                boardHandler.addNewLine(data['success'].address_id, data['success'].id, key);
        
                                // Send success message
                                boardHandler.showNotification(data['success'].type, data['success'].title, data['success'].message);
                            }
                        }
                    });
                });
                break;
            case 2:
                $("#parcelCreateButton").click(function(e) {
                    e.preventDefault()
                    $(".error_message").remove();
                    var a = "/add_parcel";
                    var postObj = new FormData();


                    postObj.append('width', 0);
                    postObj.append('depth', 0);
                    postObj.append('height', 0);
                    postObj.append('weight', 0);
                    postObj.append('name', $("#add_name").val());
                    postObj.append('content', $("#add_content").val());
                    postObj.append('commentary', $("#add_commentary").val());
                    postObj.append('store', $("#add_store").val());
                    postObj.append('parcel_verification_bill', $("#add_parcel_verification_bill")[0].files[0]);

            
                
                    $.ajax({
                        url: a,
                        type: 'POST',
                        data: postObj,
                        contentType: false,
                        processData: false,
                        success: function(data) {
                            data = JSON.parse(data);
                            // If something
                            if(data['error']){
                                boardHandler.showNotification(data['error'].type, data['error'].title, data['error'].message);
                            }
                            else if(data['success']){
                                // // Send success message
                                boardHandler.showNotification(data['success'].type, data['success'].title, data['success'].message);
                                boardHandler.addNewLine(data['success'].parcel, data['success'].id, key);
                                // hide old form
                                document.getElementById("form_add").classList.add('none')
                                document.getElementById("form_add").style.display = 'none'
                                document.getElementById("form_add").classList.remove('active')
                            }
                        }
                    });
                });
                break;
        }
    }

    static deleteElement(id, key) {
        // Remove address STATIC
        document.getElementById(id).parentElement.parentElement.remove();

        if(key == 1) {
            mapBoxHandler.getCurrentMainAddress();
        }

    }

    static addNewLine(content, id, key) {
        const event = new Date(content.created_at),
        options = { year: 'numeric', month: 'long', day: 'numeric' },
        date = event.toLocaleDateString('fr-FR', options);
        let table = document.querySelector('.fs-sm');

        switch (key) {
            case 1:
                let ids = document.querySelectorAll('.id'),
                isMain = content.is_main != 1 ? "Secondaire" : "Principale",
                last = ids.length > 0 ? parseInt(ids[ids.length - 1].innerHTML.replace(/\D/g,'')) + 1 : 1,
                line = `
                    <tr>
                        <td>
                            <a class="fw-semibold id">${last}</a>
                            <p class="fs-sm fw-medium text-muted mbgit isMain -0">${isMain}</p>

                        </td>
                        <td class="d-xl-table-cell">
                            <a class="fw-semibold country" href="javascript:void(0)">${content.country}</a>
                            <p class="fs-sm fw-medium fulladdress text-muted mb-0">${content.address_full}</p>
                        </td>
                        <td class="d-sm-table-cell fw-semibold text-muted additional text-end">${content.additional}</td>
                        <td class="d-sm-table-cell fw-semibold text-muted zipcode text-end">${content.zip}</td>
                        <td class="d-sm-table-cell fw-semibold text-muted text-end">${content.country_code}</td>

                        <td class="d-sm-table-cell fw-semibold text-muted text-end">${date}</td>
                        <td class="d-sm-table-cell text-end">
                            <strong>${content.phone}</strong>
                        </td>
                        <td class="w-50">
                            <a id="${id}" class="edit"><img src="/public/assets/svg/edit.svg"></a>
                        </td>
                        <td class="w-50">
                            <a id="${id}" class="delete"><img src="/public/assets/svg/error.svg"></a>
                        </td>
                    </tr>
                `;
                table.innerHTML += line;

                this.eventButton(3, key);
                this.eventButton(4, key);

                mapBoxHandler.getCurrentMainAddress();
                break;
            case 2:
                line = `
                    <tr>
                        <td>
                            <a class="fw-semibold orange parcel-id" href="javascript:void(0)">${content.id_colibriles}</a>

                        </td>
                        <td class="d-xl-table-cell">
                            <a class="fw-semibold parcel-name-cart" href="javascript:void(0)">${content.name}</a>
                        </td>
                        <td>
                            <span class="fs-xs fw-semibold text-content">${content.content}</span>
                        </td>
                        <td class="d-xl-table-cell">
                            <a class="fw-semibold parcel-store" href="javascript:void(0)">${content.store}</a>
                        </td>
                        <td>
                            <span class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-info-light parcel-status text-info">${content.status}</span>
                        </td>
                        <td class="d-sm-table-cell text-end">
                            <strong>${date}</strong>
                        </td>
                        <td class="w-50"><div class="block"></div><a id="${id}" href="/download-bill?id=${id}" class=""><img src="/public/assets/svg/search.svg"></a></td>
                        <td class="w-50">
                            <div class="block active"></div>
                            <a id="${id}" class="add unactive"><img src="/public/assets/svg/add.svg"></a>
                        </td>
                        <td class="w-50">
                            
                        <a id="${id}" class="edit"><img src="/public/assets/svg/edit.svg"></a></td>
                        <td class="w-50">
                            
                        <a id="${id}" class="delete"><img src="/public/assets/svg/error.svg"></a></td>
                    </tr>
                `;
                table.innerHTML += line;

                this.eventButton(3, key);
                this.eventButton(4, key);

                boardHandler.addNewParcelInCart();
                break;
            case 3:
                line = `
                    <tr id="${id}">
                        <td>
                            <a class="fw-semibold orange parcel-id" href="javascript:void(0)">${content.parcelId}</a>

                        </td>
                        <td class="d-xl-table-cell">
                            <a class="fw-semibold parcel-name-cart" href="javascript:void(0)">${content.name}</a>
                        </td>
                       
                        <td>
                            <span class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-info-light parcel-status text-info">${content.status}</span>
                        </td>
                    
                        <td class="d-sm-table-cell text-end">
                            <strong>${content.date}</strong>
                        </td>
                        <td class="w-50"><a id="${id}" class="deleteGroup"><img src="/public/assets/svg/error.svg"></a></td>
                    </tr>
                `;
                table.innerHTML += line;
                let nodeContent = content;
                boardHandler.eventButton(6, key, nodeContent);
                // this.eventButton(4, key);

                // boardHandler.addNewParcelInCart();
                break;
        }
    }

    static updateProfil() {
        $("#updateButton").click(function (e) {
            e.preventDefault();
            let a = "/profil";
            let postObj = new FormData();

            postObj.append("email", $("#email").val());
            postObj.append("password", $("#currentPassword").val());
            postObj.append("firstname", $("#firstname").val());
            postObj.append("lastname", $("#lastname").val());
            postObj.append("phone", $("#phone").val());

            $.ajax({
                url: a,
                type: "POST",
                data: postObj,
                contentType: false,
                processData: false,
                success: function (data) {
                    data = JSON.parse(data);
                    // If something
                    if (data["error"]) {    
                        boardHandler.showNotification(data['error'].type, data['error'].title, data['error'].message);
                    }
                    if(data['success']){
                        boardHandler.showNotification(data['success'].type, data['success'].title, data['success'].message);
                    }
                },
            });
        });
    }
    

    
    static initLocalStorage() {
        let closes = document.querySelectorAll('.close');
        closes.forEach(close => {
            close.addEventListener('click', function() {
                close.parentElement.parentElement.style.display = "none";
            })
        });
        if (localStorage.getItem("parcels") == null) {
            localStorage.setItem("parcels", JSON.stringify([]));
            boardHandler.deleteParcelInCart();
        } else {
            boardHandler.initCartParcels();
        }
    }

    static eventButton(key, target, content) {
        let contents = content;
        switch (key) {
            case 1:
                let currentActive = document.querySelector("span.active"),
                    buttons = document.querySelectorAll(".list-member > span"),
                    cards = document.querySelectorAll(".member > div");

                cards[0].style.display = "block";

                buttons.forEach((button, index) => {
                    button.addEventListener("click", function () {
                        currentActive = document.querySelector("span.active");
                        currentActive.classList.remove("active");
                        button.classList.add("active");

                        for (let a = 0; a < cards.length; a++) {
                            cards[a].style.display = "none";
                        }
                        cards[index].style.display = "block";
                    });
                });
                break;
            case 2:
                let button = document.getElementById('add_address'),
                content = document.getElementById('form_add');

                button.addEventListener('click', function() {
                    content.style.display = "flex";
                })
                break;
            case 3:
                let bens = document.querySelectorAll('.delete'),
                a;
                bens.forEach((ben, index) => {
                    
                    let parent = ben.parentElement,
                    node = ben.cloneNode(true);
                    parent.appendChild(node);
                    ben.remove();


                    node.addEventListener('click', function() {
                        node.parentElement.parentElement.innerHTML += `
                        <td class=\"comfirmed\">
                            <div> 
                                <div>
                                    <h4>Voulez vous vraiment supprimer cette ligne ?<h4>
                                </div>
                                <div>
                                    <button class=\"button error\">Non</button>
                                    <button class=\"button success\">Oui</button>
                                </div>
                            </div>
                        </td>`;

                        let comfirmed = document.querySelector('.comfirmed');
                        let buttonComfirmed = document.querySelectorAll('.button.success');
                        let buttonRefused = document.querySelectorAll('.button.error');


                        // ADD COMFIRME MODAL


                        comfirmed.style.display = "flex";
                        buttonRefused.forEach(button => {
                            button.addEventListener('click', function() {
                                button.parentElement.parentElement.parentElement.remove();
                                boardHandler.eventButton(3, target, target);
                            })
                        });

                        buttonComfirmed.forEach(button => {
                            button.addEventListener('click', function() {
                                let postObj = new FormData();

                                switch (target) {
                                    case 1:
                                        a = "/delete-address";
                                        postObj.append('id', node.id);
                                        break;
                                    case 2:
                                        a = "/delete-parcel";
                                        postObj.append('id_colibriles', document.querySelectorAll('.parcel-id')[index].innerHTML);
                                        postObj.append('id_parcel', node.id);
                                        break;
                                }
                                
                            
                            
                                
                                $.ajax({
                                    url: a,
                                    type: 'POST',
                                    data: postObj,
                                    contentType: false,
                                    processData: false,
                                    success: function(data) {
                                        data = JSON.parse(data);
                                        // If something
                                        if(data['error']) {
                                            // display error
                                            $("form").append("<div class=error_message></div>")
                                            $(".error_message").html(data["error"])
                                        }
                                        else if(data['success']) {
                                            // Delete Address
                                            boardHandler.deleteElement(data['success'].id, target);

                                            // Update button
                                            boardHandler.eventButton(3, target, target);
                                            button.parentElement.parentElement.parentElement.remove();



                                            // Send message
                                            boardHandler.showNotification(data['success'].type, data['success'].title, data['success'].message);
                                        }
                                    }
                                });
                            })
                        });
                    })
                });
                break;
            case 4:
                let edits = document.querySelectorAll('.edit'),
                widget = document.getElementById('form_edit');
                let button_node, edit_form, edit_button;

                switch (target) {
                    case 1:
                        let edit_address_full = document.getElementById('edit_full_address'),
                        edit_additional_address = document.getElementById('edit_additional_address'),
                        edit_city = document.getElementById('edit_city'),
                        edit_zipcode = document.getElementById('edit_zipcode'),
                        edit_country = document.getElementById('edit_country'),
                        edit_phone = document.getElementById('edit_phone'),
                        edit_address_type = document.getElementById('edit_address_type'),
                        edit_country_code = document.getElementById('edit_country_code')


                        edit_button = document.getElementById('editAddressFormButton');
                        button_node = edit_button.cloneNode(true);
                        edit_form = document.querySelector('.edit_form');
                        break;
                    case 2:
                        let edit_name = document.getElementById('edit_name'),
                        edit_content = document.getElementById('edit_content'),
                        edit_commentary = document.getElementById('edit_commentary'),
                        edit_store = document.getElementById('edit_store'),
                        edit_parcel_verification_bill = document.getElementById('edit_parcel_verification_bill');

                        edit_button = document.getElementById('editParcelButton')
                        button_node = edit_button.cloneNode(true);
                        edit_form = document.querySelector('.edit_form');
                        break;
                }



                
                edit_form.appendChild(button_node);
                edit_button.remove();

                edits.forEach((edit, index) => {
                    let parent = edit.parentElement;
                    let node = edit.cloneNode(true);
                    let a;
                    parent.appendChild(node);
                    edit.remove();
                    node.addEventListener('click', function() {
                        widget.style.display = "flex";
                        widget.classList.add('active');
                        window.location.replace(location.pathname + "#form_edit");
                        let postObj = new FormData();
                        switch (target) {
                            case 1:
                                a = "/get-address";
                                // ID
                                postObj.append('id', node.id);
                                break;
                            case 2:
                                a = "/get-parcel";
                                // ID COLIS
                                postObj.append('id', node.parentElement.parentElement.firstElementChild.firstElementChild.innerHTML);
                                postObj.append('id_parcel', node.id);

                                break;
                        }


                        
                    
                        
                        $.ajax({
                            url: a,
                            type: 'POST',
                            data: postObj,
                            contentType: false,
                            processData: false,
                            success: function(data) {
                                data = JSON.parse(data);
                                // If something
                                if(data['error']) {
                                    // display error
                                    $("form").append("<div class=error_message></div>")
                                    $(".error_message").html(data["error"])
                                }
                                else if(data['success']) {
                                    let id_colibriles;
                                    switch (target) {
                                        case 1:
                                            let edit_address_full = document.getElementById('edit_full_address'),
                                            is_main = parseInt(data['success'].is_main);
                                            edit_address_full.value = data['success'].address_full;
                                            edit_additional_address.value = data['success'].additional;
                                            edit_city.value = data['success'].city;
                                            edit_zipcode.value = data['success'].zip;
                                            edit_country.value = data['success'].country;
                                            edit_country_code.value = data['success'].country_code;
                                            edit_phone.value = data['success'].phone;
                                            edit_address_type.options[is_main].selected = true;
                                            edit_country_code.value = data['success'].country_code;

                                            edit_form.id = data['success'].address_id;
                                            break;
                                        case 2:
                                            let parcel = data['success']['parcel'];
                                            edit_name.value = parcel.name;
                                            edit_content.value = parcel.content;
                                            edit_commentary.value = parcel.commentary;
                                            edit_store.value = parcel.store;

                                            edit_form.id = data['success']['id'];
                                            id_colibriles = data['success']['id_colibriles'];
                                            break;
                                    }

                                    button_node.addEventListener('click', function(e) {
                                        e.preventDefault();
                                        let a;
                                        let postObj = new FormData();

                                        switch (target) {
                                            case 1:
                                                a = "/edit-address";
                                                let edit_address_full = document.getElementById('edit_full_address');
                                                postObj.append('id', edit_form.id);
                                                postObj.append('address', edit_address_full.value);
                                                postObj.append('additional', edit_additional_address.value);
                                                postObj.append('city', edit_city.value);
                                                postObj.append('zip', edit_zipcode.value);
                                                postObj.append('country', edit_country.value);
                                                postObj.append('country_code', edit_country_code.value);
                                                postObj.append('phone', edit_phone.value);
                                                postObj.append('is_main', edit_address_type.value);
                                                break;
                                            case 2:
                                                a = "/edit-parcel";
                                                let edit_parcel_verification_bill = document.getElementById('edit_parcel_verification_bill')
                                                // ID COLIS
                                                postObj.append('id', edit_form.id);
                                                postObj.append('id_colibriles', id_colibriles)

                                                postObj.append('name', edit_name.value);
                                                postObj.append('content', edit_content.value);
                                                postObj.append('commentary', edit_commentary.value);
                                                postObj.append('store', edit_store.value);
                                                (edit_parcel_verification_bill.files[0] ? postObj.append('parcel_verification_bill', edit_parcel_verification_bill.files[0]) : "");

                                                break;
                                        }
                                    
                                        
                                        $.ajax({
                                            url: a,
                                            type: 'POST',
                                            data: postObj,
                                            contentType: false,
                                            processData: false,
                                            success: function(data) {
                                                data = JSON.parse(data);
                                                // If something
                                                if(data['error']) {
                                                    // displa error
                                                    boardHandler.showNotification(data['error'].type, data['error'].title, data['error'].message);
                                                }
                                                else if(data['success']) {
                                                    widget.style.display = "none";
                                                    widget.classList.remove('active');
                                                    // Update list
                                                    boardHandler.eventButton(4, target);
                                                    // // Show notification
                                                    boardHandler.showNotification(data['success'].type, data['success'].title, data['success'].message);


                                                    switch (target) {
                                                        case 1:
                                                            boardHandler.updateLineElement(data['success'], index, target);
                                                            
                                                            mapBoxHandler.getCurrentMainAddress();
                                                            break;
                                                        case 2:
                                                            boardHandler.updateLineElement(data['success']["parcel"], index, target);
                                                            break;
                                                    }


                                                }
                                            }
                                        });
                                    });
                                }
                            }
                        });
                    })
                });
                break;
            case 5:
                let addButtons = document.querySelectorAll('.add');
                let parcelIds = document.querySelectorAll('.parcel-group-id');
                let parcelNames = document.querySelectorAll('.parcel-group-name');
                let parcelDates = document.querySelectorAll('.parcel-group-date > strong');
                let parcelContent = document.querySelectorAll('.parcel-group-content');
                let parcelStore = document.querySelectorAll('.parcel-group-store');
                let parcelStatus = document.querySelectorAll('.parcel-group-status');




                addButtons.forEach((button, index) => {
                    let parent = button.parentElement,
                    node = button.cloneNode(true);
                    parent.appendChild(node);
                    button.remove();

                    node.addEventListener('click', function() {
                        let content = {
                            "id": parcelIds[index].parentElement.parentElement.id,
                            "parcelId": parcelIds[index].innerHTML,
                            "name": parcelNames[index].innerHTML,
                            "content": parcelContent[index].innerHTML,
                            "store": parcelStore[index].innerHTML,
                            "status": parcelStatus[index].innerHTML,
                            "date": parcelDates[index].innerHTML
                        };

                        boardHandler.addNewLine(content, content.id, 3);
                        parcelIds[index].parentElement.parentElement.style.display = "none";
                    })
                });
                // boardHandler.addNewLine();
                break;
            case 6:
                let bensParcelGroups = document.querySelectorAll('.deleteGroup');
                bensParcelGroups.forEach((ben, index) => {
                    
                    let parent = ben.parentElement,
                    formParcels = document.getElementById('content-parcels'),
                    node = ben.cloneNode(true);
                    parent.appendChild(node);
                    ben.remove();


                    node.addEventListener('click', function() {
                        let idNode = node.parentElement.parentElement.id;
                        // ! Delete node Parcel Group
                        node.parentElement.parentElement.remove();
                        document.getElementById(idNode).style.display = "table";

                    })
                });
                break;
        }
    }

    static updateLineElement(content, index, target) {
        let address = document.querySelectorAll('.fs-sm > tr');

        switch (target) {
            case 1:
                let isMain = document.querySelectorAll('.isMain'),
                country = document.querySelectorAll('.country'),
                fulladdress = document.querySelectorAll('.fulladdress'),
                zipcode = document.querySelectorAll('.zipcode');
                
                isMain[(index)].innerHTML = (content.is_main != 0 ? "Principale" : "Secondaire");
                country[(index)].innerHTML = content.country;
                fulladdress[(index)].innerHTML = content.address_full;
                zipcode[(index)].innerHTML = content.zip;
                break;
            case 2:
                let parcel_id = document.querySelectorAll('.parcel-id'),
                parcel_name_cart= document.querySelectorAll('.parcel-name-cart'),
                text_content = document.querySelectorAll('.text-content'),
                parcel_store = document.querySelectorAll('.parcel-store'),
                parcel_status = document.querySelectorAll(".parcel-status");

                parcel_id[index].innerHTML = content.id_colibriles;
                parcel_name_cart[index].innerHTML = content.name;
                text_content[index].innerHTML = content.content;
                parcel_store[index].innerHTML = content.store;
                parcel_status[index].innerHTML = content.status;

                break;
        }
    }

    static async showNotification(type, title, message) {
        let notification = document.querySelectorAll('.notification'),
        notifications = document.querySelector('.notifications');


        let notification_clone = notification[0].cloneNode(true);
        // Reset clone
        notification_clone.classList.remove('none');
        notifications.appendChild(notification_clone);
        await boardHandler.delay(100);

        notification_clone.classList.add('active');
        notification_clone.lastElementChild.classList.add('active');


        notification_clone.firstElementChild.firstElementChild.classList.add(type);
        notification_clone.firstElementChild.lastElementChild.classList.add(type);


        notification_clone.firstElementChild.lastElementChild.innerHTML = title;
        notification_clone.firstElementChild.nextElementSibling.firstElementChild.innerHTML = message;
        await boardHandler.delay(4000);

        notification_clone.classList.remove('active');
        await boardHandler.delay(500);
        notification_clone.remove();
    }


    static usePoint() {
        let button = document.getElementById("is-member");
        let isMember = document.getElementsByClassName("button-board")[1].classList.contains("is-member");
        if (isMember) {
            button.addEventListener("click", function () {
                let a = "/usepoint";
                let postObj = new FormData();
                postObj.append("use_points", true);

                $.ajax({
                    url: a,
                    type: "POST",
                    data: postObj,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        data = JSON.parse(data);
                        if (data["error"]) {
                            boardHandler.showNotification(data['error'].type, data['error'].title, data['error'].message);
                        } else if(data['success']){
                            boardHandler.showNotification(data['success'].type, data['success'].title, data['success'].message);
                        }
                    },
                });
            });
        }
    }

    static getStaticChart() {
        const ctx = document.getElementById("board").getContext('2d');


        $.ajax({
            url: "/get-orders",
            type: 'GET',
            contentType: false,
            processData: false,
            success: function(orders) {
                orders = JSON.parse(orders);
                let contents = [];
                let dates = [];


                orders.forEach((order, index) => {
                    const event = new Date(order.order.created_at),
                    month = { month: 'long' },
                    year = { year: 'numeric' },
                    dateMonth = event.toLocaleDateString('fr-FR', month),
                    dateYear = event.toLocaleDateString('fr-FR', year);
                    
                    let isExist = false;

                    if(new Date().getFullYear() == dateYear) {
                        contents.forEach((content, index) => {
                            if(dateMonth in content) {
                                isExist = true;
                                content[dateMonth] += 1
                            }
                        });
    
    
                        if(!isExist) {
                            dates.push(dateMonth);

                            if(order.order_parcel && "parcels_number" in order.order_parcel) {
                                contents.push({
                                    [dateMonth]: parseInt(order.order_parcel.parcels_number)
                                });
                            } else {
                                contents.push({
                                    [dateMonth]: 1
                                });
                            } 
                        }
                    }
                });

                


                let contentForChart = {};
                let isCheck;
                let total = 0;
                for (let loop = 0; loop <= 11; loop++) {
                    isCheck = false;

                    switch (loop) {
                        case 0:
                            contents.forEach((content, index) => {
                                if("janvier" in content) {
                                    isCheck = true;
                                    total += content.janvier;
                                    contentForChart.janvier = content.janvier;
                                    
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.janvier =  0;

                                }
                            });
                            // janvier
                            break;
                        case 1:
                             contents.forEach((content, index) => {
                                if("février" in content) {
                                    isCheck = true;
                                    total += content.février;

                                    contentForChart.février = content.février;
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.février =  0;

                                }
                            });
                            // février
                            break;
                        case 2:
                             contents.forEach((content, index) => {
                                if("mars" in content) {
                                    isCheck = true;
                                    total += content.mars;

                                    contentForChart.mars = content.mars;
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.mars =  0;

                                }
                            });
                            // mars
                            break;
                        case 3:
                             contents.forEach((content, index) => {
                                if("avril" in content) {
                                    isCheck = true;
                                    total += content.avril;

                                    contentForChart.avril = content.avril;
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.avril =  0;

                                }
                            });
                            // avril
                            break;
                        case 4:
                             contents.forEach((content, index) => {
                                if("mai" in content) {
                                    isCheck = true;
                                    total += content.mai;

                                    contentForChart.mai = content.mai;
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.mai =  0;

                                }
                            });
                            // mai
                            break;
                        case 5:
                             contents.forEach((content, index) => {
                                if("juin" in content) {
                                    isCheck = true;
                                    total += content.juin;

                                    contentForChart.juin = content.juin;
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.juin =  0;

                                }
                            });
                            // juin
                            break;
                        case 6:
                             contents.forEach((content, index) => {
                                if("juillet" in content) {
                                    isCheck = true;
                                    total += content.juillet;

                                    contentForChart.juillet = content.juillet;
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.juillet =  0;

                                }
                            });
                            // juillet
                            break;
                        case 7:
                             contents.forEach((content, index) => {
                                if("août" in content) {
                                    isCheck = true;
                                    total += content.août;

                                    contentForChart.août = content.août;
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.août =  0;

                                }
                            });
                            // août
                            break;
                        case 8:
                             contents.forEach((content, index) => {
                                if("septembre" in content) {
                                    isCheck = true;
                                    total += content.septembre;

                                    contentForChart.septembre = content.septembre;
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.septembre =  0;

                                }
                            });
                            // septembre
                            break;
                        case 9:
                             contents.forEach((content, index) => {

                                if("octobre" in content) {
                                    isCheck = true;
                                    total += content.octobre;

                                    contentForChart.octobre = content.octobre;
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.octobre =  0;

                                }
                            });
                            // octobre
                            break;
                        case 10:
                             contents.forEach((content, index) => {
                                if("novembre" in content) {
                                    isCheck = true;
                                    total += content.novembre;

                                    contentForChart.novembre = content.novembre;
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.novembre =  0;

                                }
                            });
                            // novembre
                            break;
                        case 11:
                             contents.forEach((content, index) => {
                                if("décembre" in content) {
                                    isCheck = true;
                                    total += content.décembre;

                                    contentForChart.décembre = content.décembre;
                                } else if(!isCheck && index >= (contents.length - 1)) {
                                    contentForChart.décembre =  0;

                                }
                            });
                            // décembre
                            break;
                    }
                    
                }


                let totalDom = document.querySelector('.total');
                totalDom.innerHTML = total;


                const meanSend = new Chart(ctx, {
                    type: "line",
                    data: {
                        datasets: [
                            {
                                fill: "origin", // 0: fill to 'origin'
                                tension: 0.5,
                                label: "",
                                data: contentForChart,
        
                                backgroundColor: ["#fcc7ab59"],
        
                                borderColor: ["#fcc7ab59"],
                                borderWidth: 1,
                            },
                        ],
                    },
                    options: {
                        hover: {
                            mode: "nearest",
                            intersect: false,
                        },
                        layout: {
                            padding: {
                                left: -10,
                                bottom: -12,
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        elements: {
                            point: {
                                radius: 1,
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false,
                                    drawBorder: false,
                                },
                                ticks: {
                                    display: false,
                                },
                            },
                            y: {
                                suggestedMax: 10,
                                grid: {
                                    display: false,
                                    drawBorder: false,
                                },
                                ticks: {
                                    display: false,
                                },
                            },
                        },
                    },
                });
            }
        });
    }

    static initCheckout() {
        let checkout_button = document.getElementById("checkout");
        if (localStorage.getItem("parcels") == null) {
            checkout_button.setAttribute("disabled", "");
        } else {
            checkout_button.addEventListener("click", function () {
                let parcels = JSON.parse(localStorage.getItem("parcels"));
                let parcel_ids = [];
                parcels.forEach((element) => {
                    parcel_ids.push(element["id"]);
                });
                let a = "/cart-offers";
                let postObj = new FormData();

                postObj.append("parcels-id", parcel_ids);
                $.ajax({
                    url: a,
                    type: "POST",
                    data: postObj,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        data = JSON.parse(data);
                        // If something
                        if (data["error"]) {
                            boardHandler.showNotification(data['error'].type, data['error'].title, data['error'].message);
                        }
                        else if(data['success']){
                            window.location.replace('/offers');
                        }
                    },
                });
            });
        }
    }


    static deleteParcelInCart() {
        let node = document
            .querySelector("#content-parcel-cart")
            .cloneNode(true);
        document.querySelector("#content-parcel-cart").innerHTML =
            node.innerHTML;
        let buttons = document.querySelectorAll(".delete-parcel-cart");
        let parcels = JSON.parse(localStorage.getItem("parcels"));

        buttons.forEach((button, index) => {
            button.addEventListener("click", function () {
                parcels = JSON.parse(localStorage.getItem("parcels"));
                boardHandler.enableParcel(parcels[index].id);
                button.parentElement.parentElement.classList.add("delete");
                parcels.splice(index, 1);
                localStorage.setItem("parcels", JSON.stringify(parcels));
                setTimeout(function () {
                    button.parentElement.parentElement.remove();
                    boardHandler.deleteParcelInCart();
                }, 500);
            });
        });
    }

    static enableParcel(id) {
        let parcels = document.querySelectorAll(".parcel-id");
        let button_parcels = document.querySelectorAll(".add");
        let blocks = document.querySelectorAll(".block");


        for (let index = 0; index < parcels.length; index++) {
            if (parcels[index].innerHTML === id) {
                button_parcels[index].classList.remove('unactive');
                blocks[index].classList.remove('active');

            }
        }
    }
    static addNewParcelInCart() {
        let buttons = document.querySelectorAll(".add"),
            content = document.getElementById("content-parcel-cart"),
            name = document.querySelectorAll(".parcel-name-cart"),
            blocks = document.querySelectorAll(".block"),
            id = document.querySelectorAll(".parcel-id");
        // Insert new parcel
        for (let index = 0; index < buttons.length; index++) {

            let parent = buttons[index].parentElement,
            node = buttons[index].cloneNode(true);
            parent.appendChild(node);
            buttons[index].remove();


            node.addEventListener("click", function (params) {
                let contentElements = document.querySelectorAll("#content-parcel-cart > div");
                if(contentElements.length <= 0) {
                    node.classList.add('unactive');
                    blocks[index].classList.add('active');

                    let parcels = JSON.parse(localStorage.getItem("parcels")) || [];
                    let currentName = name[index].innerHTML,
                        currentId = id[index].innerHTML;
                    let local = {
                        id: currentId,
                        parcel: `
                        <div>
                            <div>
                                <img src="/public/assets/svg/parcel.svg" alt="">
                            </div>
                            <div>
                                <h4>${currentName}</h4>
                                <p>${currentId}</p>
                            </div>
                            <div>
                                <img class="delete-parcel-cart" src="/public/assets/svg/close-small.svg" alt="">
                            </div>
                        </div>
                        `,
                    };
                    content.innerHTML += local.parcel;
                    parcels.push(local);
                    localStorage.setItem("parcels", JSON.stringify(parcels));
                    boardHandler.deleteParcelInCart();
                    
                    let a = "/get-string-cart";
                    let postObj = new FormData();
                    postObj.append("is_valid", 1);

                    $.ajax({
                        url: a,
                        type: "POST",
                        data: postObj,
                        contentType: false,
                        processData: false,
                        success: function(data) {
                            data = JSON.parse(data);
                            // If something
                            if(data['error']){
                                //display error
                                boardHandler.showNotification(
                                    data["error"].type,
                                    data["error"].title,
                                    data["error"].message
                                );
                            }
                            if(data['success']){
                                boardHandler.showNotification(
                                    data["success"].type,
                                    data["success"].title,
                                    data["success"].message
                                );
                            }
                            // Else its good
                            // User has been added
                        }
                    });
                    setTimeout(function () {
                        const modal = document.querySelector(".modal-cart");
                        modal.classList.add("active");
                    }, 4500);
                } else {
                    let a = "/get-string-cart";
                    let postObj = new FormData();
                    postObj.append("is_valid", 0);

                    $.ajax({
                        url: a,
                        type: "POST",
                        data: postObj,
                        contentType: false,
                        processData: false,
                        success: function(data) {
                            data = JSON.parse(data);
                            // If something
                            if(data['error']){
                                //display error
                                boardHandler.showNotification(
                                    data["error"].type,
                                    data["error"].title,
                                    data["error"].message
                                );
                            }
                            if(data['success']){
                                boardHandler.showNotification(
                                    data["success"].type,
                                    data["success"].title,
                                    data["success"].message
                                );
                            }
                            // Else its good
                            // User has been added
                        }
                    });

                }
            });
        }
    }
    static initCartParcels() {
        let content = document.getElementById("content-parcel-cart"),
            parcels = JSON.parse(localStorage.getItem("parcels")),
            ids = document.querySelectorAll(".parcel-id"),
            blocks = document.querySelectorAll(".block"),
            button_parcels = document.querySelectorAll(".add");
            if (parcels != null && ids.length > 0 && location.pathname != "/my-groups") {
                // Set disable attribute if id exist
                for (let a = 0; a < parcels.length; a++) {
                    let id = parcels[a].id;
                    for (let b = 0; b < button_parcels.length; b++) {
                        if (ids[b].innerHTML === id) {
                            button_parcels[b].classList.add('unactive');
                            blocks[b].classList.add('active');

                        }
                    }
                }
            }
            // Add Parcels in cart if exist
            for (let index = 0; index < parcels.length; index++) {
                content.innerHTML += parcels[index].parcel;
            }
            boardHandler.deleteParcelInCart();
    }
    static activeCart() {
        const cart = document.getElementById("cart");
        const modal = document.querySelector(".modal-cart");
        const close_button = document.getElementById("close-cart");

        close_button.addEventListener("click", function () {
            modal.classList.remove("active");
        });
        cart.addEventListener("click", function () {
            modal.classList.add("active");
        });
    }


    static initOffers() {
        let buttonOffers = document.querySelectorAll('div.offers > div');

        const frais_reception = document.querySelector('.frais-reception'),
        frais_regroupement = document.querySelector('.frais-regroupement'),
        livraison_ht = document.querySelector('.livraison-ht'),
        taxe_carburant = document.querySelector('.taxe-carburant'),
        tva = document.querySelector('.tva'),
        ttc = document.querySelector('.ttc');
        buttonOffers.forEach((offer, index) => {
            offer.addEventListener('click', function() {
                // Uncheck checkbox if it was previously checked
                document.getElementById("verification").checked = false;
                for (let index = 0; index < buttonOffers.length; index++) {
                    buttonOffers[index].classList.remove('active');
                }
                offer.classList.add('active');
                livraison_ht.innerHTML = parseFloat(offers[index].rate_ht).toFixed(2) + " €";
                taxe_carburant.innerHTML = parseFloat(offers[index].fuel_tax).toFixed(2) + " €";
                tva.innerHTML = parseFloat(offers[index].vat).toFixed(2) + " €";
                ttc.innerHTML = parseFloat(offers[index].total_final).toFixed(2) + " €";
            })
        });
    }
}