/**
 * Widget form handling
 */

export default class widgetHandler {
    static actionForm() {
        let buttonsControl = document.querySelectorAll('.control > a');
        let contents = document.querySelectorAll('.content .info');
        let services = document.querySelector('.services');
        let drop = document.querySelector('.dropdown-menu ');

        services.addEventListener('click', function() {
            drop.style.display = "flex";
        })

        buttonsControl.forEach(function callback(button, index){
            button.addEventListener('click', function(){
                
                console.log(contents);
                switch (index) {
                    case 0:
                        switch (button.className) {
                            case "active":
                                break;
                        
                            default:
                                contents[index].style.display = "flex";
                                contents[index + 1].style.display = "none";
                                buttonsControl[index + 1].className = "";
                                break;
                        }
                        break;
                
                    case 1:
                        switch (button.className) {
                            case "active":
                                break;
                        
                            default:
                                contents[index].style.display = "flex";
                                contents[index - 1].style.display = "none";
                                buttonsControl[index - 1].className = "";
                                break;
                        }
                        break;;
                }
                button.className = "active";
            })
        });
    }
}