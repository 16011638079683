import $ from "jquery";

export default class trackingHandler {
    static trackingFormHandler(){
        $("#submitTrackingButton").click(function(e) {
            e.preventDefault()
            $(".error_message").remove();
            var a = "/tracking";
            var postObj = new FormData();

            postObj.append('transporter', $("#transporter").val());
            postObj.append('tracking_number', $("#tracking_number").val());
            $.ajax({
                url: a,
                type: 'POST',
                data: postObj,
                contentType: false,
                processData: false,
                success: function(data) {
                    data = JSON.parse(data);
                    if(data['error']){
                        trackingHandler.showContent(data);
                    }
                    else if(data['success']){
                        console.log(data['success']);
                        trackingHandler.showContent(0, data["success"]);
                    }
                }
            });
        });

        $("#submitTrackingButtonSwitch").click(function(e) {
            e.preventDefault()
            $(".error_message").remove();
            var a = "/tracking";
            var postObj = new FormData();

            postObj.append('transporter', $("#transporter_switch").val());
            postObj.append('tracking_number', $("#tracking_number_switch").val());
            
            $.ajax({
                url: a,
                type: 'POST',
                data: postObj,
                contentType: false,
                processData: false,
                success: function(data) {
                    data = JSON.parse(data);
                    if(data['error']){
                    }
                    else if(data['success']){
                        console.log(data['success']);
                        trackingHandler.showContent(1, data["success"]);
                    }
                }
            });
        });
    }

    static showContent(key, data) {
        let modalShow = document.querySelector('.modal-tracking');
        let modal = document.querySelector('.modal-tracking .content');
        let content = document.querySelector('.modal-tracking .content > div');
        let close = document.querySelector('.modal-tracking img');
        let status = document.querySelector('.modal-tracking .content span');
        let select;
        close.addEventListener('click', function(){
            modalShow.style.display = "none";
        })

        content.innerHTML = "";

        switch (key) {
            case 0:
                select = $("#transporter").val();
                break;
            case 1: 
                select = $("#transporter_switch").val();
                break;
        }

        switch (select) {
            case "Chronopost":
            case "Colissimo":
                // LD143089095FR
                if(data.code != "NOT_FOUND") {
                    if(data.returnCode != 400){
                        modalShow.style.display = "flex";
                        status.innerHTML = data.shipment.product;
                        
                        data.shipment.event.forEach(event => {
                            let line = `
                            <div>
                                <p>${event.date}</p>
                                <p>${event.label}</p>
                            </div>`;
                            content.innerHTML +=  line;
                        });
                        content.innerHTML +=  `<a target="_blank" class="color orange" href="${data.shipment.url}">LAPOSTE ${data.shipment.idShip}</a>`;
                    }
                    else {
                        // Get error
                        var a = "/tracking-error";
                        var postObj = new FormData();
                        $.ajax({
                            url: a,
                            type: 'POST',
                            data: postObj,
                            contentType: false,
                            processData: false,
                            success: function(data) {
                                data = JSON.parse(data);
                                modalShow.style.display = "flex";
                                let line = `
                                <div>
                                    <p><strong>${data}</strong></p>
                                </div>`;
                                content.innerHTML +=  line;
                            }
                        });
                    }
                }
                else {
                    // Get error
                    var a = "/tracking-error";
                    var postObj = new FormData();
                    $.ajax({
                        url: a,
                        type: 'POST',
                        data: postObj,
                        contentType: false,
                        processData: false,
                        success: function(data) {
                            data = JSON.parse(data);
                            modalShow.style.display = "flex";
                            let line = `
                            <div>
                                <p><strong>${data}</strong></p>
                            </div>`;
                            content.innerHTML +=  line;
                        }
                    });
                }
                
                break;
            case "DHL":
                // 7777777770
                if(data.status != "400" && data.status != "404") {
                    modalShow.style.display = "flex";
                    data.shipments.forEach(parcel => {
                        status.innerHTML = "Statut : " + parcel.status.statusCode;
                        parcel.events.forEach(event => {
                            let line = `
                            <div>
                                <p>${event.timestamp}</p>
                                <p><strong>${event.location.address.addressLocality}</strong></p>
                                <p>${event.description}</p>
                            </div>`;
                            content.innerHTML +=  line;
                        });
                    });
                }
                else {
                    // Get error
                    var a = "/tracking-error";
                    var postObj = new FormData();
                    $.ajax({
                        url: a,
                        type: 'POST',
                        data: postObj,
                        contentType: false,
                        processData: false,
                        success: function(data) {
                            data = JSON.parse(data);
                            modalShow.style.display = "flex";
                            let line = `
                            <div>
                                <p><strong>${data}</strong></p>
                            </div>`;
                            content.innerHTML +=  line;
                        }
                    });
                }
                break;
            case "UPS":
                //1Z291Y080464856327
                if("trackResponse" in data) {
                    if("warnings" in data.trackResponse.shipment[0] ){
                        // Display error
                        var a = "/tracking-error";
                        var postObj = new FormData();
                        $.ajax({
                            url: a,
                            type: 'POST',
                            data: postObj,
                            contentType: false,
                            processData: false,
                            success: function(data) {
                                data = JSON.parse(data);
                                modalShow.style.display = "flex";
                                let line = `
                                <div>
                                    <p><strong>${data}</strong></p>
                                </div>`;
                                content.innerHTML +=  line;
                            }
                        });
                    }
                    else {
                        modalShow.style.display = "flex";
                        data.trackResponse.shipment[0].package[0].activity.forEach(parcel => {
                            status.style.display = "none";
                            let line = `
                            <div>
                                <p>${parcel.date}</p>
                                <p><strong>${parcel.location.address.city}</strong></p>
                                <p>${parcel.status.description}</p>
                            </div>`;
                            content.innerHTML +=  line;
                        });
                    }
                }
                else {
                    // Get error
                        var a = "/tracking-error";
                        var postObj = new FormData();
                        $.ajax({
                            url: a,
                            type: 'POST',
                            data: postObj,
                            contentType: false,
                            processData: false,
                            success: function(data) {
                                data = JSON.parse(data);
                                modalShow.style.display = "flex";
                                let line = `
                                <div>
                                    <p><strong>${data}</strong></p>
                                </div>`;
                                content.innerHTML +=  line;
                            }
                        });
                }
                break;
        }
    }
} 
