import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
mapboxgl.accessToken = "pk.eyJ1IjoiY3N0YWNreSIsImEiOiJja3ZsZG5zdmwxNTVzMnZ0a20wZDR4dG1xIn0.FwE9eabshpYKcDa3FZhVOw";
/**
 * Mapbox form handling
 */

 export default class mapBoxHandler {
    static getCurrentMainAddress() {
      let geocoder = new google.maps.Geocoder(),
      city = document.querySelector('.city-main'),
      zipcode = document.querySelector('.zipcode-main'),
      full = document.querySelector('.fulladdress-main'),
      country = document.querySelectorAll('.country'),
      mains = document.querySelectorAll('.isMain'),
      zipcodes = document.querySelectorAll('.zipcode'),
      fulladdress = document.querySelectorAll(".fulladdress"),
      canva = document.querySelector('.mapboxgl-interactive'),
      all_in_canva = document.querySelectorAll('#mapbox > *'),
      address = "";
      


      mains.forEach((main, index) => {
        switch (main.innerHTML) {
          case "Principale":
            address = fulladdress[index].innerHTML + " " + zipcodes[index].innerHTML;
            zipcode.innerHTML = zipcodes[index].innerHTML;
            full.innerHTML = fulladdress[index].innerHTML;
            city.innerHTML = country[index].innerHTML;
            break;
        }
      });


       
      geocoder.geocode({ 'address': address}, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
              if(canva != null) {
                canva.style.display = "block";
              }
              let latitude = results[0].geometry.location.lat();
              let longitude = results[0].geometry.location.lng();
              mapBoxHandler.showAddress(latitude, longitude);

          } else {
              all_in_canva.forEach(element => {
                element.remove();
              });
              zipcode.innerHTML = "Vous n'avez pas d'adresse principale de définis.";
              full.innerHTML = "";
              city.innerHTML = "";
          }
      });
    }



    static getSelectedAddress() {
      
      let geocoder = new google.maps.Geocoder(),
      city = document.querySelector('.city-main'),
      zipcode = document.querySelector('.zipcode-main'),
      full = document.querySelector('.fulladdress-main'),
      country = document.querySelectorAll('.country'),
      canva = document.querySelector('.mapboxgl-interactive'),
      all_in_canva = document.querySelectorAll('#mapbox > *'),
      address = full.innerHTML + " " + zipcode.innerHTML;
           
      
      geocoder.geocode({ 'address': address}, function (results, status) {
        
          if (status == google.maps.GeocoderStatus.OK) {
              if(canva != null) {
                canva.style.display = "block";
              }
              let latitude = results[0].geometry.location.lat();
              let longitude = results[0].geometry.location.lng();
              mapBoxHandler.showAddress(latitude, longitude);

          } else {
              all_in_canva.forEach(element => {
                element.remove();
              });
              zipcode.innerHTML = "Vous n'avez pas d'adresse principale de définis.";
              full.innerHTML = "";
              city.innerHTML = "";
          }
      });
    }

    static showAddress(lat, long) {
      const map = new mapboxgl.Map({
        container: 'mapbox', // container ID
        style: 'mapbox://styles/cstacky/ckwurmvvq439x14o1t4wzu6r0', // style URL
        center: [long, lat], // starting position
        zoom: 15, // starting zoom
        
    });

    // Zone
    const geojson = {
        'type': 'FeatureCollection',
        'features': [{
              'type': 'Feature',
              'properties': {
                'message': "Colibr'iles",
                'iconSize': [30, 30]
              },
              'geometry': {
                'type': 'Point',
                'coordinates':  [long, lat]
              }
          }]
      };

    for (const marker of geojson.features) {
        // Create a DOM element for each marker.
        const el = document.createElement('div');
        const width = marker.properties.iconSize[0];
        const height = marker.properties.iconSize[1];
        el.className = 'marker';

      //   el.style.backgroundImage = `url(/client/von-de-kay/assets/svg/cercle.svg)`;
        el.style.backgroundImage = `url(/public/assets/svg/cursor-pointer.svg)`;
        el.style.width = `${width}px`;
        el.style.height = `${height}px`;
        el.style.backgroundSize = '100%';
    
    
        // Add markers to the map.
        new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);
        }

        // Prevention spam
        map.doubleClickZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        map.scrollZoom.disable();
        map.touchZoomRotate.disable();
    }
}