import _, { isFunction } from "lodash";
import Swiper from "swiper";
import "../styles/style.scss";
import $ from "jquery";
import authHandler from "./auth";
import boardHandler from "./board";
import contactFormHandler from "./contact";
import fretBilling from "./fret";
import parcelHandler from "./parcels/create-parcel";
import widgetHandler from "./widget";
import swiperHandler from "./swiper";
import handleGroupCreation from "./parcels/group-page";
import mapBoxHandler from "./mapbox";
import trackingHandler from "./tracking";
import groupHandler from "./parcels/group-page";
import checkoutHandler from "./checkout/checkout-page";
import PaypalHandler from "./payment/paypal";
import AdminHandler from "./admin";
import AdminFilter from "./admin-filter";

class generalCall {
  estimationForm() {
    let button = document.getElementById("estimeButton");
    let bloc = document.querySelector(".offers-esti");
    button.addEventListener("click", function (e) {
      e.preventDefault();
      var a = "/estimate-offers";
      var postObj = new FormData();

      document.getElementById("longueur").value != ""
        ? postObj.append("width", document.getElementById("longueur").value)
        : "";
      document.getElementById("largeur").value != ""
        ? postObj.append("height", document.getElementById("largeur").value)
        : "";
      document.getElementById("hauteur").value != ""
        ? postObj.append("depth", document.getElementById("hauteur").value)
        : "";
      document.getElementById("poids").value != ""
        ? postObj.append("weight", document.getElementById("poids").value)
        : "";
      document.getElementById("city").value != ""
        ? postObj.append("city", document.getElementById("city").value)
        : "";
      document.getElementById("country_code").value != ""
        ? postObj.append(
            "country_code",
            document.getElementById("country_code").value
          )
        : "";
      document.getElementById("zipcode").value != ""
        ? postObj.append("zipcode", document.getElementById("zipcode").value)
        : "";

      $.ajax({
        url: a,
        type: "POST",
        data: postObj,
        contentType: false,
        processData: false,
        success: function (data) {
          data = JSON.parse(data);
          // If something
          if (data["error"]) {
            // displa error
            boardHandler.showNotification(
              data["error"].type,
              data["error"].title,
              data["error"].message
            );
          } else if (data["success"]) {
            bloc.innerHTML = "";
            let offers = data["success"].offers;
            boardHandler.showNotification(
              data["success"].type,
              data["success"].title,
              data["success"].message
            );

            offers.forEach((offer) => {
              let line = `
                                <div>
                                    <div class=\"carrier\">
                                        <div>
                                            <img src=\"${
                                              offer.image
                                            }\" alt=\"\">
                                        </div>
                                        <h4>${offer.offer}</h4>
                                    </div>
                                    <div class=\"content\">
                                        <div>
                                            <div>
                                                <img src=\"/public/assets/svg/delais.svg\" alt=\"\">
                                            </div>
                                            <div>
                                                <p>${offer.delai}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <img src=\"/public/assets/svg/description.svg\" alt=\"\">
                                            </div>
                                            <div>
                                                <p>${offer.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class=\"price\">
                                        <p>${offer.total_ttc.toFixed(2)} €</p>
                                    </div>
                                </div>
                            `;

              bloc.innerHTML += line;
            });

            // for ($i = 0; $i < count($content); $i++) {
            // $currentContent = "
            //     <div>
            //         <div class=\"carrier\">
            //             <div>
            //                 <img src=\"{$content[$i]["image"]}\" alt=\"\">
            //             </div>
            //             <h4>{$content[$i]["offer"]}</h4>
            //         </div>
            //         <div class=\"content\">
            //             <div>
            //                 <div>
            //                     <img src=\"/public/assets/svg/taxe.svg\" alt=\"\">
            //                 </div>
            //                 <div>
            //                     <p>{$content[$i]["taxes"]}</p>
            //                 </div>
            //             </div>
            //             <div>
            //                 <div>
            //                     <img src=\"/public/assets/svg/delais.svg\" alt=\"\">
            //                 </div>
            //                 <div>
            //                     <p>{$content[$i]["delai"]}.</p>
            //                 </div>
            //             </div>
            //             <div>
            //                 <div>
            //                     <img src=\"/public/assets/svg/description.svg\" alt=\"\">
            //                 </div>
            //                 <div>
            //                     <p>{$content[$i]["description"]}</p>
            //                 </div>
            //             </div>
            //         </div>
            //         <div class=\"price\">
            //             <p>" . round($content[$i]["total_final"], 2) . " €</p>
            //         </div>
            //     </div>
            //     ";

            // }
          }
        },
      });
    });
  }
  eventHeader() {
    let dropdown = document.querySelector(".dropdown"),
      burger = document.querySelector(".burger-menu"),
      menu = document.querySelector(
        "header .bottom-header > div:last-child > ul"
      ),
      close = document.querySelector("header .close"),
      lang = document.querySelector(".top-header > div:last-child"),
      modal = document.querySelector(".language"),
      selectors = document.querySelectorAll(".language > div"),
      langImage = document.querySelector(
        "header .top-header > div:last-child > div img"
      ),
      board = document.querySelector(".board");

    selectors.forEach((selector, index) => {
      selector.addEventListener("click", function () {
        var a = "/set-language";
        var postObj = new FormData();
        postObj.append(
          "language",
          selector.lastElementChild.innerHTML.toLowerCase()
        );

        $.ajax({
          url: a,
          type: "POST",
          data: postObj,
          contentType: false,
          processData: false,
          success: function (data) {
            // If something
            location.reload();
          },
        });
      });
    });

    lang.addEventListener("mouseenter", function () {
      modal.style.display = "flex";
    });

    lang.addEventListener("mouseleave", function () {
      modal.style.display = "none";
    });

    burger.addEventListener("click", function () {
      menu.classList.add("active");
    });

    close.addEventListener("click", function () {
      menu.classList.remove("active");
    });

    dropdown.addEventListener("mouseenter", function () {
      dropdown.lastElementChild.style.display = "block";
    });
    dropdown.addEventListener("mouseleave", function () {
      dropdown.lastElementChild.style.display = "none";
    });
  }
  callAxeptio() {
    window.axeptioSettings = {
      clientId: "60a275c2e34b5e74720e5388",
      cookiesVersion: "client_colibriles",
    };

    (function (d, s) {
      var t = d.getElementsByTagName(s)[0],
        e = d.createElement(s);
      e.async = true;
      e.src = "//axept.io/sdk.js";
      t.parentNode.insertBefore(e, t);
    })(document, "script");

    void 0 === window._axcb && (window._axcb = []);
    window._axcb.push(function (axeptio) {
      axeptio.on("cookies:complete", function (choices) {
        //PUSH ANALYSTIC
      });
    });
  }

  removeLoader() {
    const contents = document.querySelector(".loader > div");
    const loader = document.querySelector(".loader");

    setTimeout(function () {
      loader.style.backgroundColor = "transparent";
      contents.style.top = "100%";
    }, 1000);
    setTimeout(function () {
      loader.style.zIndex = 0;
    }, 2000);
  }

  calculHeight(key) {
    switch (key) {
      case "ecommerce":
        let divHeight = document.querySelector(
          ".ecommerce > div:first-child"
        ).clientHeight;
        let secondDiv = document.querySelector(".ecommerce > div:last-child");
        let divH2Height = document.querySelector(
          ".ecommerce > div:first-child > h2"
        ).clientHeight;
        let divPHeight = document.querySelector(
          ".ecommerce > div:first-child > p"
        ).clientHeight;
        secondDiv.style.top =
          "-" + (divHeight - 100 - (divH2Height + divPHeight)) + "px";
        break;
    }
  }

  actionHeader() {
    let header = document.querySelector("header"),
      footer = document.querySelector("footer"),
      links = document.querySelectorAll(".bottom-header li a"),
      orange = "#ED5800",
      white = "#FFF",
      posUser,
      active,
      wrapper = document.getElementById("wrapper"),
      logo = document.querySelector(".bottom-header img"),
      dropdown = document.querySelector(".dropdown"),
      copyright = document.querySelector(".copyright"),
      burger = document.querySelector(".burger-menu"),
      board = document.querySelector(".board");
    if (
      location.pathname.includes("my-orders/bill") ||
      location.pathname.includes("admin/bill")
    ) {
      header.style.display = "none";
      footer.style.display = "none";
    }

    switch (location.pathname) {
      case "/":
        this.eventHeader();
        window.addEventListener("scroll", function () {
          posUser = document.documentElement.scrollTop;
          switch (true) {
            case posUser > 150 && !active:
              active = true;
              header.style.backgroundColor = "white";
              burger.style.filter = "brightness(1)";

              for (let i = 0; i < links.length; i++) {
                links[i].style.color = orange;
              }
              links[links.length - 1].style.color = white;
              logo.src = "/public/assets/logo/logo_black.svg";

              break;
            case posUser < 150 && active:
              active = false;
              burger.style.filter = "brightness(0) invert(1)";

              header.style.backgroundColor = "transparent";
              for (let i = 0; i < links.length; i++) {
                links[i].style.color = white;
              }
              logo.src = "/public/assets/logo/logo.svg";

              break;
          }
        });
        break;
      case "/signup":
      case "/login":
      case "/forgottenpwd/reset-password":
      case "/forgottenpwd/create-new-password":
      case "/contact-us":
      case "/address":
      case "/create-group":
      case "/auth":
      case "/test":
      case "/ecommerce":
      case "/reexpedition":
      case "/aerien":
      case "/maritime":
      case "/regroupement":
      case "/adhesion":
      case "/fret":
      case "/transporteurs":
      case "/tarifs":
      case "/auth-valide":
        this.eventHeader();
        header.style.backgroundColor = "white";
        for (let i = 0; i < links.length; i++) {
          links[i].style.color = orange;
        }
        links[links.length - 1].style.color = white;
        burger.style.filter = "brightness(1)";
        wrapper.style.marginTop = header.clientHeight + "px";
        logo.src = "/public/assets/logo/logo_black.svg";
        break;
      case "/board":
      case "/profil":
      case "/member":
      case "/my-parcels":
      case "/address-colibriles":
      case "/my-address":
      case "/offers":
      case "/my-groups":
      case "/my-orders":
      case "/checkout":
      case "/admin/index":
      case "/admin/manage-users":
      case "/admin/manage-addresses":
      case "/admin/manage-parcels":
      case "/admin/manage-orders":
        header.style.display = "none";
        footer.style.display = "none";
        copyright.style.display = "none";

        // HEADER MOBILE
        let button = document.querySelector(".burger-icon");
        let modal = document.querySelector(".link-modal");
        let close = document.querySelector(".link-modal > img");

        button.addEventListener("click", function () {
          modal.classList.add("active");
        });

        close.addEventListener("click", function () {
          modal.classList.remove("active");
        });

        break;
      default:
    }
  }
}

// CALL SPECIFIC SCRIPT
document.addEventListener("readystatechange", (event) => {
  const general = new generalCall();
  //general.callAxeptio();
  general.actionHeader();

  if (location.pathname.includes("my-orders/bill")) {
    boardHandler.initLocalStorage();
    boardHandler.createPDFfromHTML();
  }
  if (location.pathname.includes("admin/bill")) {
    boardHandler.createPDFfromHTML();
  }
  switch (document.readyState) {
    case "loading":
      break;
    case "interactive":
      break;
    case "complete":
      general.removeLoader();
      switch (location.pathname) {
        case "/":
          widgetHandler.actionForm();
          swiperHandler.reviewSwiper();
          trackingHandler.trackingFormHandler();
          break;
        case "/ecommerce":
          general.calculHeight("ecommerce");
          break;
        case "/auth":
          authHandler.handleAuthUI();
          authHandler.signupForm();
          authHandler.loginForm();
          boardHandler.initAutocomplete();
          break;
        case "/signup":
          authHandler.signupForm();
          break;
        case "/login":
          authHandler.loginForm();
          break;
        case "/forgottenpwd/reset-password":
          authHandler.resetPasswordForm();
          break;
        case "/forgottenpwd/create-new-password":
          authHandler.createNewPasswordRecover();
          break;
        case "/contact-us":
          contactFormHandler();
          break;
        case "/create-group":
          handleGroupCreation();
          break;

        case "/test":
          break;

        // BOARD
        case "/board":
          boardHandler.copyAddress();
          boardHandler.getStaticChart();
          boardHandler.activeCart();
          boardHandler.initLocalStorage();
          mapBoxHandler.showAddress(48.7559776, 2.4156508);
          boardHandler.initCheckout();

          break;
        case "/tarifs":
          general.estimationForm();
          boardHandler.initAutocomplete();

          break;
        case "/member":
          boardHandler.activeCart();
          boardHandler.initLocalStorage();
          boardHandler.eventButton(1, 0);
          boardHandler.usePoint();
          PaypalHandler.handlePaypalMember();
          boardHandler.initCheckout();

          break;
        case "/my-parcels":
          boardHandler.activeCart();
          boardHandler.addNewParcelInCart();
          boardHandler.initLocalStorage();
          boardHandler.initCheckout();

          boardHandler.getStaticChart();
          boardHandler.addFormHandler(2);

          // Edit, delete, add
          boardHandler.eventButton(2, 2);
          boardHandler.eventButton(3, 2);
          boardHandler.eventButton(4, 2);

          break;

        case "/my-address":
          boardHandler.activeCart();
          boardHandler.getStaticChart();
          boardHandler.initLocalStorage();
          boardHandler.initAutocompleteEdit();
          boardHandler.initAutocomplete();
          boardHandler.addFormHandler(1);
          mapBoxHandler.getCurrentMainAddress();

          //  Edit, delete, add
          boardHandler.eventButton(2, 1);
          boardHandler.eventButton(3, 1);
          boardHandler.eventButton(4, 1);
          boardHandler.initCheckout();

          break;
        case "/address-colibriles":
          boardHandler.getStaticChart();
          boardHandler.activeCart();
          boardHandler.initLocalStorage();
          mapBoxHandler.showAddress(48.7559776, 2.4156508);
          boardHandler.initCheckout();

          break;
        case "/my-groups":
          boardHandler.activeCart();
          boardHandler.initLocalStorage();
          boardHandler.eventButton(5);
          groupHandler.addGroupForm();
          groupHandler.handleGroupDelete();
          boardHandler.initCheckout();

          break;
        case "/checkout":
          boardHandler.initLocalStorage();
          PaypalHandler.handlePaypal();
          boardHandler.calculHeightWidget();
          mapBoxHandler.getSelectedAddress();
          break;
        case "/offers":
          boardHandler.initLocalStorage();
          boardHandler.initOffers();
          checkoutHandler.getDataForCheckout();
          checkoutHandler.handleCheckboxVerification();
          checkoutHandler.handleSelectAddress();
          break;
        case "/fret":
          fretBilling();
          break;
        case "/profil":
          boardHandler.copyAddress();
          boardHandler.activeCart();
          boardHandler.initLocalStorage();
          mapBoxHandler.showAddress(48.7559776, 2.4156508);
          boardHandler.updateProfil();
          boardHandler.initCheckout();

          break;

        case "/admin/manage-users":
          AdminHandler.eventButton("delete", "users");
          AdminHandler.eventButton("edit", "users");
          AdminFilter.handleFilters("users");

          break;
        case "/admin/manage-addresses":
          AdminHandler.eventButton("delete", "addresses");
          AdminHandler.eventButton("edit", "addresses");
          boardHandler.initAutocompleteEdit();
          AdminFilter.handleFilters("addresses");

          break;
        case "/admin/manage-parcels":
          AdminHandler.eventButton("delete", "parcels");
          AdminHandler.eventButton("edit", "parcels");
          AdminHandler.eventButton("add", "add");
          AdminFilter.handleFilters("parcels");
          break;
        case "/admin/manage-orders":
          AdminHandler.eventButton("delete", "orders");
          AdminHandler.eventButton("edit", "orders");
          AdminFilter.handleFilters("orders");
          break;

        default:
      }
      break;
  }
});
