import $ from "jquery";
import boardHandler from "./board";

export default function fretBilling() {
  const buttons = document.querySelectorAll(".auth-choice > div");

  buttons.forEach((button, index) => {
    button.addEventListener("click", () => {
      console.log(index);
      switch (index) {
        case 0:
          buttons[index].classList.add("login-link");
          buttons[index + 1].classList.remove("login-link");
          document.querySelector(".society-front").style.display = "flex";
          break;
        case 1:
          buttons[index].classList.add("login-link");
          buttons[index - 1].classList.remove("login-link");
          document.querySelector(".society-front").style.display = "none";
          break;
      }
    });
  });

  $("#sendDevis").click(function (e) {
    e.preventDefault();
    $(".error_message").remove();
    var a = "/fret";
    var postObj = new FormData();

    postObj.append("society", $("#society").val());
    postObj.append("lastname", $("#lastname").val());
    postObj.append("firstname", $("#firstname").val());
    postObj.append("signupEmail", $("#signupEmail").val());
    postObj.append("phone", $("#phone").val());
    postObj.append("destination", $("#destination").val());
    postObj.append("name-target", $("#name-target").val());
    postObj.append("lastname-target", $("#lastname-target").val());
    postObj.append("mail-target", $("#mail-target").val());
    postObj.append("phone-target", $("#phone-target").val());
    postObj.append("number-pack", $("#number-pack").val());
    postObj.append("product-type", $("#product-type").val());
    postObj.append("product-colis", $("#product-colis").val());
    postObj.append("weight", $("#weight").val());
    postObj.append("price", $("#price").val());
    postObj.append("longueur", $("#longueur").val());
    postObj.append("largeur", $("#largeur").val());
    postObj.append("hauteur", $("#hauteur").val());

    const waitEndTime = localStorage.getItem("waitEndTime")
      ? localStorage.getItem("waitEndTime")
      : new Date(Date.now() - 5 * 60 * 1000);

    if (waitEndTime && new Date() < new Date(waitEndTime)) {
      boardHandler.showNotification(
        "error",
        "Limite de temps atteinte",
        "Veuillez patienter avant d'envoyer un autre e-mail."
      );
    } else {
      if (
        $("#signupEmail").val() != "" &&
        $("#destination").val() != "" &&
        $("#mail-target").val() != ""
      ) {
        $.ajax({
          url: a,
          type: "POST",
          data: postObj,
          contentType: false,
          processData: false,
          success: function (data) {
            data = JSON.parse(data);
            // If something
            if (data["error"]) {
              boardHandler.showNotification(
                data["error"].type,
                data["error"].title,
                data["error"].message
              );
            } else if (data["success"]) {
              const endTime = new Date(Date.now() + 5 * 60 * 1000);
              localStorage.setItem("waitEndTime", endTime);

              boardHandler.showNotification(
                data["success"].type,
                data["success"].title,
                data["success"].message
              );
            }
          },
        });
      } else {
        boardHandler.showNotification(
          "error",
          "Erreur formulaire",
          "Informations manquantes dans le formulaire"
        );
      }
    }
  });
}
