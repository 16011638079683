import $ from "jquery";
import { once } from "lodash";
import boardHandler from "../board";

export default class groupHandler {
    static addGroupForm() {
        let button = document.getElementById('add_group');
        let modal = document.querySelector('.board-modal');
        let input = document.querySelector('.board-modal input');
        let close = document.querySelector('.board-modal img');
        button.addEventListener('click', function() {
            if(modal.classList.contains('active')) {
                $(".error_message").remove();
                var a = "/create-group";
                let formIds = document.querySelectorAll(
                    "#add-new-parcel-group > tr"
                );
                let ids = [];
                for (let index = 0; index < formIds.length; index++) {
                    ids.push(formIds[index].id);
                }
                var postObj = new FormData();
                postObj.append("parcel_ids", ids);
                postObj.append("parcel_name", input.value);

                $.ajax({
                    url: a,
                    type: "POST",
                    data: postObj,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        data = JSON.parse(data);
                        // If something
                        if (data["error"]) {
                            // Send error message
                            boardHandler.showNotification(
                                data["error"].type,
                                data["error"].title,
                                data["error"].message
                            );
                        } else if (data["success"]) {
                            // boardHandler.addNewLine(data['success'].address_id, data['success'].id, key);
                            // Send success message
                            boardHandler.showNotification(
                                data["success"].type,
                                data["success"].title,
                                data["success"].message
                            );
                            setTimeout(() => {
                                location.reload();
                            }, 2500);
                        }
                    },
                });
            } else {
                close.addEventListener('click', function(){
                    modal.classList.remove('active');
                }, {once : true});
                modal.classList.add('active');
            }
        })

        
    }

    static handleGroupDelete() {
        let buttons = document.querySelectorAll(".delete_group");
        if (buttons.length > 0) {
            buttons.forEach((button, index) => {
                button.addEventListener("click", function () {
                    let id = button.id;
                    var a = "/delete-group";
                    var postObj = new FormData();
                    postObj.append("group_id", id);

                    $.ajax({
                        url: a,
                        type: "POST",
                        data: postObj,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            data = JSON.parse(data);
                            // If something
                            if (data["error"]) {
                                // Send error message
                                boardHandler.showNotification(
                                    data["error"].type,
                                    data["error"].title,
                                    data["error"].message
                                );
                            } else if (data["success"]) {
                                // boardHandler.addNewLine(data['success'].address_id, data['success'].id, key);
                                // Send success message
                                boardHandler.showNotification(
                                    data["success"].type,
                                    data["success"].title,
                                    data["success"].message
                                );
                                setTimeout(() => {
                                    location.reload();
                                }, 2500);
                            }
                        },
                    });
                });
            });
        }
    }
}
