import $ from "jquery";
import boardHandler from '../board';


export default class PaypalHandler
{
    static handlePaypal(){

        $.ajax({
            url: "get-payment-info",
            type: "GET",
            contentType: false,
            processData: false,
            success: function(ajaxData) {
                ajaxData = JSON.parse(ajaxData);
                if(ajaxData['success']){
                    const payment_info = ajaxData['success'];
                    
                    paypal.Buttons( {
                        style:  {
                            layout:  'vertical',
                            color:   'blue',
                            shape:   'rect',
                            label:   'paypal'
                        },
                        createOrder: function(data, actions)  {
                            return actions.order.create( {
                                payer: {
                                    name: {
                                        given_name: payment_info.firstname,
                                        surname: payment_info.lastname
                                    },
                                    email_address: payment_info.mail,
                                    phone: {
                                        phone_type: "MOBILE",
                                        phone_number: {
                                            national_number: payment_info.phone
                                        }
                                    }
                                },
                                purchase_units: [{
                                    amount:{
                                        value: parseFloat(payment_info.totalPrice).toFixed(2),
                                    }
                                }],
                                application_context: {        
                                    shipping_preference: 'NO_SHIPPING'
                                }
                            });
                        },
                        onApprove: (data, actions) => {
                            return actions.order.capture().then(function(orderData) {
                                let postObj = new FormData();
                                postObj.append("is_validated", true);
                                postObj.append("payment_choice", "Paypal");
                                postObj.append("payment_id", orderData.id);
                                postObj.append("payment_status", orderData.status);

                                //

                                $.ajax({
                                    url: "/validate-order",
                                    type: "POST",
                                    data: postObj,
                                    contentType: false,
                                    processData: false,
                                    success: function(result) {
                                        result = JSON.parse(result);
                                        // If something
                                        if(result['error']){
                                            
                                        }
                                        if(result['success']){
                                            
                                            let successModal = document.querySelector('.success-modal');
                                            successModal.classList.add('active');
                                            window.localStorage.removeItem('parcels');
                                                                                   
                                        }
                                    }
                                });
                                
                            });
                        }
                    }).render('#paypal-button-container');
                }
                // Else its good
                // User has been added
            }
        });
        

        
    }


    static handlePaypalMember(){

        let modal = document.querySelector('#modal-payment');
        let close = document.querySelector('#modal-payment > img');
        let buttons = document.querySelectorAll('.button-board');


        

        buttons.forEach(button => {
            if(!button.classList.contains('is-member')) {
                button.removeAttribute("href");
                button.addEventListener('click', function() {
                    modal.classList.add('active');
                })
            }

        });
        close.addEventListener('click', function() {
            modal.classList.remove('active');
        })

        paypal.Buttons( {
            style:  {
                layout:  'vertical',
                color:   'blue',
                shape:   'rect',
                label:   'paypal'
            },
            createOrder: function(data, actions)  {
                return actions.order.create( {
                    purchase_units: [{
                        amount:{
                            value: 10,
                        }
                    }]
                });
            },
            onApprove: (data, actions) => {
                return actions.order.capture().then(function(orderData) {
                    let postObj = new FormData();
                    postObj.append("is_validated", true);
                    postObj.append("payment_choice", "Paypal");
                    postObj.append("payment_id", orderData.id);
                    postObj.append("payment_status", orderData.status);
                    $.ajax({
                        url: "/new-member",
                        type: "POST",
                        data: postObj,
                        contentType: false,
                        processData: false,
                        success: function(result) {
                            result = JSON.parse(result);
                            // If something
                            if (result["error"]) {
                                boardHandler.showNotification(result['error'].type, result['error'].title, result['error'].message);
                            } else if(result['success']){
                                boardHandler.showNotification(result['success'].type, result['success'].title, result['success'].message);
                                setTimeout(() => {
                                    location.reload();
                                }, 4000);
                            }
                        }
                    });
                });
            }
        }).render('#paypal-button');

        
    }


}