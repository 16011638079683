// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css/bundle';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


/**
 * Swiper form handling
 */

 export default class swiperHandler {
    static reviewSwiper() {
        const swiper = new Swiper('.reviews', {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 60,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            navigation: {
                nextEl: ".swiper-left_r",
                prevEl: ".swiper-right_r",
            },
            breakpoints: {
                //  QUERY
            },
        });
    }
}