import $ from "jquery";


export default class parcelHandler
{

    
    // static getParcelToModify(button, modal){
    //     var a = "/parcel";
    //     var id = $(button).closest('.parcel-item').find("#parcel-id").text();
    //     $.ajax({
    //         url: a,
    //         type: 'GET',
    //         data: {
    //             id_colibriles : id
    //         },
    //         success: function(data) {
    //             data = JSON.parse(data);
    //             // If something
    //             if(data['error']){
    //                 // display error
    //                 return null;
    //             }
    //             else if(data['success']){
    //                 parcelHandler.updateModalForModify(data["parcel"], modal)

    //                 return data["parcel"];
    //             }
    //         }
    //     });
    // }

    // static updateModalForModify(parcel, modal){
    //     $(modal).find('#width').val(parcel['width'])
    //     $(modal).find('#height').val(parcel['height'])
    //     $(modal).find('#depth').val(parcel['depth'])
    //     $(modal).find('#weight').val(parcel['weight'])
    //     $(modal).find('#name').val(parcel['name'])
    //     $(modal).find('#content').val(parcel['content'])
    //     $(modal).find('#store').val(parcel['store'])
    //     $(modal).find('#id_colibriles').val(parcel['id_colibriles'])
    //     $(modal).find('#status').val(parcel['status'])
    //     modal.style.display = "block";
        
        
    // }
}